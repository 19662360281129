import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonAllPrimaryPreview(props) {
  const {
    ButtonRoundedLgPrimaryBasic,
    ButtonRoundedLgPrimaryElevated,
    ButtonRoundedLgPrimaryDisabled,
    ButtonRoundedLgPrimaryLeadingIcon,
    ButtonRoundedLgPrimaryTrailingIcon,
    ButtonRoundedLgPrimaryBadge,
    ButtonRoundedLgPrimaryIcon,
    ButtonRoundedLgPrimaryAnimated,
  } = props

  const {
    ButtonRoundedBasePrimaryBasic,
    ButtonRoundedBasePrimaryElevated,
    ButtonRoundedBasePrimaryDisabled,
    ButtonRoundedBasePrimaryLeadingIcon,
    ButtonRoundedBasePrimaryTrailingIcon,
    ButtonRoundedBasePrimaryBadge,
    ButtonRoundedBasePrimaryIcon,
    ButtonRoundedBasePrimaryAnimated,
  } = props

  const {
    ButtonRoundedSmPrimaryBasic,
    ButtonRoundedSmPrimaryElevated,
    ButtonRoundedSmPrimaryDisabled,
    ButtonRoundedSmPrimaryLeadingIcon,
    ButtonRoundedSmPrimaryTrailingIcon,
    ButtonRoundedSmPrimaryBadge,
    ButtonRoundedSmPrimaryIcon,
    ButtonRoundedSmPrimaryAnimated,
  } = props

  return (
    <>
      <div className="flex w-full flex-col items-start justify-center gap-20">
        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryBasic}
            componentName="ButtonRoundedLgPrimaryBasic"
          >
            {/*    <!-- Component: Large primary basic button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Large button</span>
            </button>
            {/*    <!-- End Large primary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryBasic}
            componentName="ButtonRoundedBasePrimaryBasic"
          >
            {/*    <!-- Component: Base primary basic button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Base button</span>
            </button>
            {/*    <!-- End Base primary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryBasic}
            componentName="ButtonRoundedSmPrimaryBasic"
          >
            {/*    <!-- Component: Small primary basic button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Small button</span>
            </button>
            {/*    <!-- End Small primary basic button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryElevated}
            componentName="ButtonRoundedLgPrimaryElevated"
          >
            {/*    <!-- Component: Large primary elevated button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white shadow-lg shadow-emerald-200 transition duration-300 hover:bg-emerald-600 hover:shadow-md hover:shadow-emerald-200 focus:bg-emerald-700 focus:shadow-md focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Elevated large</span>
            </button>
            {/*    <!-- End Large primary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryElevated}
            componentName="ButtonRoundedBasePrimaryElevated"
          >
            {/*    <!-- Component: Base primary elevated button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white shadow-md shadow-emerald-200 transition duration-300 hover:bg-emerald-600 hover:shadow-sm hover:shadow-emerald-200 focus:bg-emerald-700 focus:shadow-sm focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Elevated base</span>
            </button>
            {/*    <!-- End Base primary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryElevated}
            componentName="ButtonRoundedSmPrimaryElevated"
          >
            {/*    <!-- Component: Small primary elevated button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white shadow-md shadow-emerald-200 transition duration-300 hover:bg-emerald-600 hover:shadow-sm hover:shadow-emerald-200 focus:bg-emerald-700 focus:shadow-sm focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Elevated small</span>
            </button>
            {/*    <!-- End Small primary elevated button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryDisabled}
            componentName="ButtonRoundedLgPrimaryDisabled"
          >
            {/*    <!-- Component: Large primary disabled button --> */}
            <button
              disabled
              className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
            >
              <span>Disabled large</span>
            </button>
            {/*    <!-- End Large primary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryDisabled}
            componentName="ButtonRoundedBasePrimaryDisabled"
          >
            {/*    <!-- Component: Base primary disabled button --> */}
            <button
              disabled
              className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
            >
              <span>Disabled base</span>
            </button>
            {/*    <!-- End Base primary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryDisabled}
            componentName="ButtonRoundedSmPrimaryDisabled"
          >
            {/*    <!-- Component: Small primary disabled button --> */}
            <button
              disabled
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
            >
              <span>Disabled small</span>
            </button>
            {/*    <!-- End Small primary disabled button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryLeadingIcon}
            componentName="ButtonRoundedLgPrimaryLeadingIcon"
          >
            {/*    <!-- Component: Large primary button with leading icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-01 desc-01"
                >
                  <title id="title-01">Icon title</title>
                  <desc id="desc-01">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryLeadingIcon}
            componentName="ButtonRoundedBasePrimaryLeadingIcon"
          >
            {/*    <!-- Component: Base primary button with leading icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-06 desc-06"
                >
                  <title id="title-06">Icon title</title>
                  <desc id="desc-06">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryLeadingIcon}
            componentName="ButtonRoundedSmPrimaryLeadingIcon"
          >
            {/*    <!-- Component: Small primary button with leading icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-11 desc-11"
                >
                  <title id="title-11">Icon title</title>
                  <desc id="desc-11">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with leading icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryTrailingIcon}
            componentName="ButtonRoundedLgPrimaryTrailingIcon"
          >
            {/*    <!-- Component: Large primary button with trailing icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-02 desc-02"
                >
                  <title id="title-02">Icon title</title>
                  <desc id="desc-02">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryTrailingIcon}
            componentName="ButtonRoundedBasePrimaryTrailingIcon"
          >
            {/*    <!-- Component: Base primary button with trailing icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-07 desc-07"
                >
                  <title id="title-07">Icon title</title>
                  <desc id="desc-07">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryTrailingIcon}
            componentName="ButtonRoundedSmPrimaryTrailingIcon"
          >
            {/*    <!-- Component: Small primary button with trailing icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-12 desc-12"
                >
                  <title id="title-12">Icon title</title>
                  <desc id="desc-12">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with trailing icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryBadge}
            componentName="ButtonRoundedLgPrimaryBadge"
          >
            {/*    <!-- Component: Large primary button with badge  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Icon & Badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-03 desc-03"
                >
                  <title id="title-03">Icon title</title>
                  <desc id="desc-03">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Large primary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryBadge}
            componentName="ButtonRoundedBasePrimaryBadge"
          >
            {/*    <!-- Component: Base primary button with badge  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-08 desc-08"
                >
                  <title id="title-08">Icon title</title>
                  <desc id="desc-08">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Base primary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryBadge}
            componentName="ButtonRoundedSmPrimaryBadge"
          >
            {/*    <!-- Component: Small primary button with badge  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-13 desc-13"
                >
                  <title id="title-13">Icon title</title>
                  <desc id="desc-13">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-0.5 -right-0.5 rounded-full bg-pink-500 p-1">
                  <span className="sr-only">8 new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Small primary button with badge  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryIcon}
            componentName="ButtonRoundedLgPrimaryIcon"
          >
            {/*    <!-- Component: Large primary button with icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 self-center whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-6">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-04 desc-04"
                >
                  <title id="title-04">Icon title</title>
                  <desc id="desc-04">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryIcon}
            componentName="ButtonRoundedBasePrimaryIcon"
          >
            {/*    <!-- Component: Base primary button with icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 self-center whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-5">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-09 desc-09"
                >
                  <title id="title-09">Icon title</title>
                  <desc id="desc-09">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryIcon}
            componentName="ButtonRoundedSmPrimaryIcon"
          >
            {/*    <!-- Component: Small primary button with icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 self-center whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-4">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-14 desc-14"
                >
                  <title id="title-14">Icon title</title>
                  <desc id="desc-14">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgPrimaryAnimated}
            componentName="ButtonRoundedLgPrimaryAnimated"
          >
            {/*    <!-- Component: Large primary button with animation  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-05 desc-05"
                >
                  <title id="title-05">Icon title</title>
                  <desc id="desc-05">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBasePrimaryAnimated}
            componentName="ButtonRoundedBasePrimaryAnimated"
          >
            {/*    <!-- Component: Base primary button with animation  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-5">
                <svg
                  className="h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-10 desc-10"
                >
                  <title id="title-10">Icon title</title>
                  <desc id="desc-10">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmPrimaryAnimated}
            componentName="ButtonRoundedSmPrimaryAnimated"
          >
            {/*    <!-- Component: Small primary button with animation  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-4 w-4 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-15 desc-15"
                >
                  <title id="title-15">Icon title</title>
                  <desc id="desc-15">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with animation  --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
