import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"
// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import ButtonsAllPrimaryPreview from "../../../library/components/button/react/_preview/primary"
import ButtonsAllPrimaryRoundedPreview from "../../../library/components/button/react/_preview/primary-rounded"

import ButtonsAllSecondaryPreview from "../../../library/components/button/react/_preview/secondary"
import ButtonsAllSecondaryRoundedPreview from "../../../library/components/button/react/_preview/secondary-rounded"

import ButtonsAllOutlinePreview from "../../../library/components/button/react/_preview/outline"

import ButtonsAllLinkPreview from "../../../library/components/button/react/_preview/link"

import ButtonsAllAppPreview from "../../../library/components/button/react/_preview/app"

import HeroButtonComponent from "../../../library/components/button/react/_preview/heroButtonComponent"

// All Primary
// Large
const ButtonRoundedLgPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-basic.jsx")
const ButtonRoundedLgPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-basic.html")

const ButtonRoundedLgPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-elevated.jsx")
const ButtonRoundedLgPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-elevated.html")

const ButtonRoundedLgPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-disabled.jsx")
const ButtonRoundedLgPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-disabled.html")

const ButtonRoundedLgPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-leading_icon.jsx")
const ButtonRoundedLgPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-leading_icon.html")

const ButtonRoundedLgPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-trailing_icon.jsx")
const ButtonRoundedLgPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-trailing_icon.html")

const ButtonRoundedLgPrimaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-badge.jsx")
const ButtonRoundedLgPrimaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-badge.html")

const ButtonRoundedLgPrimaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-icon.jsx")
const ButtonRoundedLgPrimaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-icon.html")

const ButtonRoundedLgPrimaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/primary-animated.jsx")
const ButtonRoundedLgPrimaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/primary-animated.html")

// Base
const ButtonRoundedBasePrimaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-basic.jsx")
const ButtonRoundedBasePrimaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-basic.html")

const ButtonRoundedBasePrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-elevated.jsx")
const ButtonRoundedBasePrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-elevated.html")

const ButtonRoundedBasePrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-disabled.jsx")
const ButtonRoundedBasePrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-disabled.html")

const ButtonRoundedBasePrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-leading_icon.jsx")
const ButtonRoundedBasePrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-leading_icon.html")

const ButtonRoundedBasePrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-trailing_icon.jsx")
const ButtonRoundedBasePrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-trailing_icon.html")

const ButtonRoundedBasePrimaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-badge.jsx")
const ButtonRoundedBasePrimaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-badge.html")

const ButtonRoundedBasePrimaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-icon.jsx")
const ButtonRoundedBasePrimaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-icon.html")

const ButtonRoundedBasePrimaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/primary-animated.jsx")
const ButtonRoundedBasePrimaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/primary-animated.html")

// Small
const ButtonRoundedSmPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-basic.jsx")
const ButtonRoundedSmPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-basic.html")

const ButtonRoundedSmPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-elevated.jsx")
const ButtonRoundedSmPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-elevated.html")

const ButtonRoundedSmPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-disabled.jsx")
const ButtonRoundedSmPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-disabled.html")

const ButtonRoundedSmPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-leading_icon.jsx")
const ButtonRoundedSmPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-leading_icon.html")

const ButtonRoundedSmPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-trailing_icon.jsx")
const ButtonRoundedSmPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-trailing_icon.html")

const ButtonRoundedSmPrimaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-badge.jsx")
const ButtonRoundedSmPrimaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-badge.html")

const ButtonRoundedSmPrimaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-icon.jsx")
const ButtonRoundedSmPrimaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-icon.html")

const ButtonRoundedSmPrimaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/primary-animated.jsx")
const ButtonRoundedSmPrimaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/primary-animated.html")

// All Secondary
// Large
const ButtonRoundedLgSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-basic.jsx")
const ButtonRoundedLgSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-basic.html")

const ButtonRoundedLgSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-elevated.jsx")
const ButtonRoundedLgSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-elevated.html")

const ButtonRoundedLgSecondaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-disabled.jsx")
const ButtonRoundedLgSecondaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-disabled.html")

const ButtonRoundedLgSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-leading_icon.jsx")
const ButtonRoundedLgSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-leading_icon.html")

const ButtonRoundedLgSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-trailing_icon.jsx")
const ButtonRoundedLgSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-trailing_icon.html")

const ButtonRoundedLgSecondaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-badge.jsx")
const ButtonRoundedLgSecondaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-badge.html")

const ButtonRoundedLgSecondaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-icon.jsx")
const ButtonRoundedLgSecondaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-icon.html")

const ButtonRoundedLgSecondaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/secondary-animated.jsx")
const ButtonRoundedLgSecondaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/secondary-animated.html")
// Base
const ButtonRoundedBaseSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-basic.jsx")
const ButtonRoundedBaseSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-basic.html")

const ButtonRoundedBaseSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-elevated.jsx")
const ButtonRoundedBaseSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-elevated.html")

const ButtonRoundedBaseSecondaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-disabled.jsx")
const ButtonRoundedBaseSecondaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-disabled.html")

const ButtonRoundedBaseSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-leading_icon.jsx")
const ButtonRoundedBaseSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-leading_icon.html")

const ButtonRoundedBaseSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-trailing_icon.jsx")
const ButtonRoundedBaseSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-trailing_icon.html")

const ButtonRoundedBaseSecondaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-badge.jsx")
const ButtonRoundedBaseSecondaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-badge.html")

const ButtonRoundedBaseSecondaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-icon.jsx")
const ButtonRoundedBaseSecondaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-icon.html")

const ButtonRoundedBaseSecondaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/secondary-animated.jsx")
const ButtonRoundedBaseSecondaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/secondary-animated.html")

// Small
const ButtonRoundedSmSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-basic.jsx")
const ButtonRoundedSmSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-basic.html")

const ButtonRoundedSmSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-elevated.jsx")
const ButtonRoundedSmSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-elevated.html")

const ButtonRoundedSmSecondaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-disabled.jsx")
const ButtonRoundedSmSecondaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-disabled.html")

const ButtonRoundedSmSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-leading_icon.jsx")
const ButtonRoundedSmSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-leading_icon.html")

const ButtonRoundedSmSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-trailing_icon.jsx")
const ButtonRoundedSmSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-trailing_icon.html")

const ButtonRoundedSmSecondaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-badge.jsx")
const ButtonRoundedSmSecondaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-badge.html")

const ButtonRoundedSmSecondaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-icon.jsx")
const ButtonRoundedSmSecondaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-icon.html")

const ButtonRoundedSmSecondaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/secondary-animated.jsx")
const ButtonRoundedSmSecondaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/secondary-animated.html")

// All Primary Rounded
// Large
const ButtonRoundedFullLgPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-basic.jsx")
const ButtonRoundedFullLgPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-basic.html")

const ButtonRoundedFullLgPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-elevated.jsx")
const ButtonRoundedFullLgPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-elevated.html")

const ButtonRoundedFullLgPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-disabled.jsx")
const ButtonRoundedFullLgPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-disabled.html")

const ButtonRoundedFullLgPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-leading_icon.jsx")
const ButtonRoundedFullLgPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-leading_icon.html")

const ButtonRoundedFullLgPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-trailing_icon.jsx")
const ButtonRoundedFullLgPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-trailing_icon.html")

const ButtonRoundedFullLgPrimaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-badge.jsx")
const ButtonRoundedFullLgPrimaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-badge.html")

const ButtonRoundedFullLgPrimaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-icon.jsx")
const ButtonRoundedFullLgPrimaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-icon.html")

const ButtonRoundedFullLgPrimaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/primary-animated.jsx")
const ButtonRoundedFullLgPrimaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/primary-animated.html")

// Base
const ButtonRoundedFullBasePrimaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-basic.jsx")
const ButtonRoundedFullBasePrimaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-basic.html")

const ButtonRoundedFullBasePrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-elevated.jsx")
const ButtonRoundedFullBasePrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-elevated.html")

const ButtonRoundedFullBasePrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-disabled.jsx")
const ButtonRoundedFullBasePrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-disabled.html")

const ButtonRoundedFullBasePrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-leading_icon.jsx")
const ButtonRoundedFullBasePrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-leading_icon.html")

const ButtonRoundedFullBasePrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-trailing_icon.jsx")
const ButtonRoundedFullBasePrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-trailing_icon.html")

const ButtonRoundedFullBasePrimaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-badge.jsx")
const ButtonRoundedFullBasePrimaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-badge.html")

const ButtonRoundedFullBasePrimaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-icon.jsx")
const ButtonRoundedFullBasePrimaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-icon.html")

const ButtonRoundedFullBasePrimaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/primary-animated.jsx")
const ButtonRoundedFullBasePrimaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/primary-animated.html")

// Small
const ButtonRoundedFullSmPrimaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-basic.jsx")
const ButtonRoundedFullSmPrimaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-basic.html")

const ButtonRoundedFullSmPrimaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-elevated.jsx")
const ButtonRoundedFullSmPrimaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-elevated.html")

const ButtonRoundedFullSmPrimaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-disabled.jsx")
const ButtonRoundedFullSmPrimaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-disabled.html")

const ButtonRoundedFullSmPrimaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-leading_icon.jsx")
const ButtonRoundedFullSmPrimaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-leading_icon.html")

const ButtonRoundedFullSmPrimaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-trailing_icon.jsx")
const ButtonRoundedFullSmPrimaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-trailing_icon.html")

const ButtonRoundedFullSmPrimaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-badge.jsx")
const ButtonRoundedFullSmPrimaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-badge.html")

const ButtonRoundedFullSmPrimaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-icon.jsx")
const ButtonRoundedFullSmPrimaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-icon.html")

const ButtonRoundedFullSmPrimaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/primary-animated.jsx")
const ButtonRoundedFullSmPrimaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/primary-animated.html")

// All Secondary Rounded
// Large
const ButtonRoundedFullLgSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-basic.jsx")
const ButtonRoundedFullLgSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-basic.html")

const ButtonRoundedFullLgSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-elevated.jsx")
const ButtonRoundedFullLgSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-elevated.html")

const ButtonRoundedFullLgSecondaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-disabled.jsx")
const ButtonRoundedFullLgSecondaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-disabled.html")

const ButtonRoundedFullLgSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-leading_icon.jsx")
const ButtonRoundedFullLgSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-leading_icon.html")

const ButtonRoundedFullLgSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-trailing_icon.jsx")
const ButtonRoundedFullLgSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-trailing_icon.html")

const ButtonRoundedFullLgSecondaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-badge.jsx")
const ButtonRoundedFullLgSecondaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-badge.html")

const ButtonRoundedFullLgSecondaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-icon.jsx")
const ButtonRoundedFullLgSecondaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-icon.html")

const ButtonRoundedFullLgSecondaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/lg/secondary-animated.jsx")
const ButtonRoundedFullLgSecondaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/lg/secondary-animated.html")

// Base
const ButtonRoundedFullBaseSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-basic.jsx")
const ButtonRoundedFullBaseSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-basic.html")

const ButtonRoundedFullBaseSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-elevated.jsx")
const ButtonRoundedFullBaseSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-elevated.html")

const ButtonRoundedFullBaseSecondaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-disabled.jsx")
const ButtonRoundedFullBaseSecondaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-disabled.html")

const ButtonRoundedFullBaseSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-leading_icon.jsx")
const ButtonRoundedFullBaseSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-leading_icon.html")

const ButtonRoundedFullBaseSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-trailing_icon.jsx")
const ButtonRoundedFullBaseSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-trailing_icon.html")

const ButtonRoundedFullBaseSecondaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-badge.jsx")
const ButtonRoundedFullBaseSecondaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-badge.html")

const ButtonRoundedFullBaseSecondaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-icon.jsx")
const ButtonRoundedFullBaseSecondaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-icon.html")

const ButtonRoundedFullBaseSecondaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/base/secondary-animated.jsx")
const ButtonRoundedFullBaseSecondaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/base/secondary-animated.html")

// Small
const ButtonRoundedFullSmSecondaryBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-basic.jsx")
const ButtonRoundedFullSmSecondaryBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-basic.html")

const ButtonRoundedFullSmSecondaryElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-elevated.jsx")
const ButtonRoundedFullSmSecondaryElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-elevated.html")

const ButtonRoundedFullSmSecondaryDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-disabled.jsx")
const ButtonRoundedFullSmSecondaryDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-disabled.html")

const ButtonRoundedFullSmSecondaryLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-leading_icon.jsx")
const ButtonRoundedFullSmSecondaryLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-leading_icon.html")

const ButtonRoundedFullSmSecondaryTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-trailing_icon.jsx")
const ButtonRoundedFullSmSecondaryTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-trailing_icon.html")

const ButtonRoundedFullSmSecondaryBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-badge.jsx")
const ButtonRoundedFullSmSecondaryBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-badge.html")

const ButtonRoundedFullSmSecondaryIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-icon.jsx")
const ButtonRoundedFullSmSecondaryIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-icon.html")

const ButtonRoundedFullSmSecondaryAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded-full/sm/secondary-animated.jsx")
const ButtonRoundedFullSmSecondaryAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded-full/sm/secondary-animated.html")

// All Outline
// Large
const ButtonRoundedLgOutlineBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-basic.jsx")
const ButtonRoundedLgOutlineBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-basic.html")

const ButtonRoundedLgOutlineElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-elevated.jsx")
const ButtonRoundedLgOutlineElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-elevated.html")

const ButtonRoundedLgOutlineDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-disabled.jsx")
const ButtonRoundedLgOutlineDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-disabled.html")

const ButtonRoundedLgOutlineLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-leading_icon.jsx")
const ButtonRoundedLgOutlineLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-leading_icon.html")

const ButtonRoundedLgOutlineTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-trailing_icon.jsx")
const ButtonRoundedLgOutlineTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-trailing_icon.html")

const ButtonRoundedLgOutlineBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-badge.jsx")
const ButtonRoundedLgOutlineBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-badge.html")

const ButtonRoundedLgOutlineIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-icon.jsx")
const ButtonRoundedLgOutlineIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-icon.html")

const ButtonRoundedLgOutlineAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/outline-animated.jsx")
const ButtonRoundedLgOutlineAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/outline-animated.html")

// Base
const ButtonRoundedBaseOutlineBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-basic.jsx")
const ButtonRoundedBaseOutlineBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-basic.html")

const ButtonRoundedBaseOutlineElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-elevated.jsx")
const ButtonRoundedBaseOutlineElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-elevated.html")

const ButtonRoundedBaseOutlineDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-disabled.jsx")
const ButtonRoundedBaseOutlineDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-disabled.html")

const ButtonRoundedBaseOutlineLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-leading_icon.jsx")
const ButtonRoundedBaseOutlineLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-leading_icon.html")

const ButtonRoundedBaseOutlineTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-trailing_icon.jsx")
const ButtonRoundedBaseOutlineTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-trailing_icon.html")

const ButtonRoundedBaseOutlineBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-badge.jsx")
const ButtonRoundedBaseOutlineBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-badge.html")

const ButtonRoundedBaseOutlineIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-icon.jsx")
const ButtonRoundedBaseOutlineIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-icon.html")

const ButtonRoundedBaseOutlineAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/outline-animated.jsx")
const ButtonRoundedBaseOutlineAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/outline-animated.html")

// Small
const ButtonRoundedSmOutlineBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-basic.jsx")
const ButtonRoundedSmOutlineBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-basic.html")

const ButtonRoundedSmOutlineElevatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-elevated.jsx")
const ButtonRoundedSmOutlineElevatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-elevated.html")

const ButtonRoundedSmOutlineDisabledJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-disabled.jsx")
const ButtonRoundedSmOutlineDisabledHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-disabled.html")

const ButtonRoundedSmOutlineLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-leading_icon.jsx")
const ButtonRoundedSmOutlineLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-leading_icon.html")

const ButtonRoundedSmOutlineTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-trailing_icon.jsx")
const ButtonRoundedSmOutlineTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-trailing_icon.html")

const ButtonRoundedSmOutlineBadgeJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-badge.jsx")
const ButtonRoundedSmOutlineBadgeHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-badge.html")

const ButtonRoundedSmOutlineIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-icon.jsx")
const ButtonRoundedSmOutlineIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-icon.html")

const ButtonRoundedSmOutlineAnimatedJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/outline-animated.jsx")
const ButtonRoundedSmOutlineAnimatedHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/outline-animated.html")

// All Link
// Large
const ButtonRoundedLgLinkBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/link-basic.jsx")
const ButtonRoundedLgLinkBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/link-basic.html")

const ButtonRoundedLgLinkLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/link-leading_icon.jsx")
const ButtonRoundedLgLinkLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/link-leading_icon.html")

const ButtonRoundedLgLinkTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/link-trailing_icon.jsx")
const ButtonRoundedLgLinkTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/link-trailing_icon.html")

// Base
const ButtonRoundedBaseLinkBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/link-basic.jsx")
const ButtonRoundedBaseLinkBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/link-basic.html")

const ButtonRoundedBaseLinkLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/link-leading_icon.jsx")
const ButtonRoundedBaseLinkLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/link-leading_icon.html")

const ButtonRoundedBaseLinkTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/link-trailing_icon.jsx")
const ButtonRoundedBaseLinkTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/link-trailing_icon.html")

// Small
const ButtonRoundedSmLinkBasicJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/link-basic.jsx")
const ButtonRoundedSmLinkBasicHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/link-basic.html")

const ButtonRoundedSmLinkLeadingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/link-leading_icon.jsx")
const ButtonRoundedSmLinkLeadingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/link-leading_icon.html")

const ButtonRoundedSmLinkTrailingIconJsx = require("!!raw-loader!../../../library/components/button/react/rounded/sm/link-trailing_icon.jsx")
const ButtonRoundedSmLinkTrailingIconHTML = require("!!raw-loader!../../../library/components/button/html/rounded/sm/link-trailing_icon.html")

// All App
// Large
const ButtonRoundedLgAppPrimaryAppleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/app-primary-apple.jsx")
const ButtonRoundedLgAppPrimaryAppleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/app-primary-apple.html")

const ButtonRoundedLgAppPrimaryGoogleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/app-primary-google.jsx")
const ButtonRoundedLgAppPrimaryGoogleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/app-primary-google.html")

const ButtonRoundedLgAppBasicAppleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/app-basic-apple.jsx")
const ButtonRoundedLgAppBasicAppleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/app-basic-apple.html")

const ButtonRoundedLgAppBasicGoogleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/app-basic-google.jsx")
const ButtonRoundedLgAppBasicGoogleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/app-basic-google.html")

const ButtonRoundedLgAppOutlineAppleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/app-outline-apple.jsx")
const ButtonRoundedLgAppOutlineAppleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/app-outline-apple.html")

const ButtonRoundedLgAppOutlineGoogleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/lg/app-outline-google.jsx")
const ButtonRoundedLgAppOutlineGoogleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/lg/app-outline-google.html")

// Base
const ButtonRoundedBaseAppPrimaryAppleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/app-primary-apple.jsx")
const ButtonRoundedBaseAppPrimaryAppleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/app-primary-apple.html")

const ButtonRoundedBaseAppPrimaryGoogleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/app-primary-google.jsx")
const ButtonRoundedBaseAppPrimaryGoogleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/app-primary-google.html")

const ButtonRoundedBaseAppBasicAppleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/app-basic-apple.jsx")
const ButtonRoundedBaseAppBasicAppleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/app-basic-apple.html")

const ButtonRoundedBaseAppBasicGoogleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/app-basic-google.jsx")
const ButtonRoundedBaseAppBasicGoogleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/app-basic-google.html")

const ButtonRoundedBaseAppOutlineAppleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/app-outline-apple.jsx")
const ButtonRoundedBaseAppOutlineAppleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/app-outline-apple.html")

const ButtonRoundedBaseAppOutlineGoogleJsx = require("!!raw-loader!../../../library/components/button/react/rounded/base/app-outline-google.jsx")
const ButtonRoundedBaseAppOutlineGoogleHTML = require("!!raw-loader!../../../library/components/button/html/rounded/base/app-outline-google.html")

export default function ButtonsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "PrimaryButtons",
      title: "Primary buttons",
      active_tab: 1,
    },
    {
      component_name: "SecondaryButtons",
      title: "Secondary",
      active_tab: 1,
    },
    {
      component_name: "PrimaryRoundedButtons",
      title: "Rounded full primary",
      active_tab: 1,
    },
    {
      component_name: "SecondaryRoundedButtons",
      title: "Rounded full secondary",
      active_tab: 1,
    },
    {
      component_name: "OutlineButtons",
      title: "Outline",
      active_tab: 1,
    },
    {
      component_name: "LinkButtons",
      title: "Link",
      active_tab: 1,
    },
    {
      component_name: "AppButtons",
      title: "App",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Buttons - WindUI Component Library"
        ogtitle="Tailwind CSS Buttons - WindUI Component Library"
        description="Button components are interactive elements activated by a user, that perform a programmable action, such as opening a dialog. Built with Tailwind CSS by WindUI."
        ogdescription="Button components are interactive elements activated by a user, that perform a programmable action, such as opening a dialog. Built with Tailwind CSS by WindUI."
        url="components/buttons/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Buttons, Buttons, Button Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />

      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Buttons</h1>
          <p>
            Buttons are interactive elements activated by a user with a mouse,
            keyboard, or tap. Once activated, it then performs a programmable
            action, such as submitting a form or opening a dialog.
          </p>

          {/* Buttons Preview Section */}

          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <HeroButtonComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="PrimaryButtons">Primary buttons</h3>

          <PreviewBlock
            id="PrimaryButtons"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonRoundedLgPrimaryBasicHTML.default}
            JsxComponent={ButtonRoundedLgPrimaryBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <ButtonsAllPrimaryPreview
                ButtonRoundedLgPrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryBasicHTML.default
                    : ButtonRoundedLgPrimaryBasicJsx.default
                }
                ButtonRoundedLgPrimaryElevated={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryElevatedHTML.default
                    : ButtonRoundedLgPrimaryElevatedJsx.default
                }
                ButtonRoundedLgPrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryDisabledHTML.default
                    : ButtonRoundedLgPrimaryDisabledJsx.default
                }
                ButtonRoundedLgPrimaryLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryLeadingIconHTML.default
                    : ButtonRoundedLgPrimaryLeadingIconJsx.default
                }
                ButtonRoundedLgPrimaryTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryTrailingIconHTML.default
                    : ButtonRoundedLgPrimaryTrailingIconJsx.default
                }
                ButtonRoundedLgPrimaryBadge={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryBadgeHTML.default
                    : ButtonRoundedLgPrimaryBadgeJsx.default
                }
                ButtonRoundedLgPrimaryIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryIconHTML.default
                    : ButtonRoundedLgPrimaryIconJsx.default
                }
                ButtonRoundedLgPrimaryAnimated={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedLgPrimaryAnimatedHTML.default
                    : ButtonRoundedLgPrimaryAnimatedJsx.default
                }
                ButtonRoundedBasePrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryBasicHTML.default
                    : ButtonRoundedBasePrimaryBasicJsx.default
                }
                ButtonRoundedBasePrimaryElevated={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryElevatedHTML.default
                    : ButtonRoundedBasePrimaryElevatedJsx.default
                }
                ButtonRoundedBasePrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryDisabledHTML.default
                    : ButtonRoundedBasePrimaryDisabledJsx.default
                }
                ButtonRoundedBasePrimaryLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryLeadingIconHTML.default
                    : ButtonRoundedBasePrimaryLeadingIconJsx.default
                }
                ButtonRoundedBasePrimaryTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryTrailingIconHTML.default
                    : ButtonRoundedBasePrimaryTrailingIconJsx.default
                }
                ButtonRoundedBasePrimaryBadge={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryBadgeHTML.default
                    : ButtonRoundedBasePrimaryBadgeJsx.default
                }
                ButtonRoundedBasePrimaryIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryIconHTML.default
                    : ButtonRoundedBasePrimaryIconJsx.default
                }
                ButtonRoundedBasePrimaryAnimated={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedBasePrimaryAnimatedHTML.default
                    : ButtonRoundedBasePrimaryAnimatedJsx.default
                }
                ButtonRoundedSmPrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryBasicHTML.default
                    : ButtonRoundedSmPrimaryBasicJsx.default
                }
                ButtonRoundedSmPrimaryElevated={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryElevatedHTML.default
                    : ButtonRoundedSmPrimaryElevatedJsx.default
                }
                ButtonRoundedSmPrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryDisabledHTML.default
                    : ButtonRoundedSmPrimaryDisabledJsx.default
                }
                ButtonRoundedSmPrimaryLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryLeadingIconHTML.default
                    : ButtonRoundedSmPrimaryLeadingIconJsx.default
                }
                ButtonRoundedSmPrimaryTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryTrailingIconHTML.default
                    : ButtonRoundedSmPrimaryTrailingIconJsx.default
                }
                ButtonRoundedSmPrimaryBadge={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryBadgeHTML.default
                    : ButtonRoundedSmPrimaryBadgeJsx.default
                }
                ButtonRoundedSmPrimaryIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryIconHTML.default
                    : ButtonRoundedSmPrimaryIconJsx.default
                }
                ButtonRoundedSmPrimaryAnimated={
                  activeTabs[0].active_tab === 1
                    ? ButtonRoundedSmPrimaryAnimatedHTML.default
                    : ButtonRoundedSmPrimaryAnimatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SecondaryButtons">Secondary buttons</h3>

          <PreviewBlock
            id="SecondaryButtons"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonRoundedLgSecondaryBasicHTML.default}
            JsxComponent={ButtonRoundedLgSecondaryBasicJsx.default}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <ButtonsAllSecondaryPreview
                ButtonRoundedLgSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryBasicHTML.default
                    : ButtonRoundedLgSecondaryBasicJsx.default
                }
                ButtonRoundedLgSecondaryElevated={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryElevatedHTML.default
                    : ButtonRoundedLgSecondaryElevatedJsx.default
                }
                ButtonRoundedLgSecondaryDisabled={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryDisabledHTML.default
                    : ButtonRoundedLgSecondaryDisabledJsx.default
                }
                ButtonRoundedLgSecondaryLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryLeadingIconHTML.default
                    : ButtonRoundedLgSecondaryLeadingIconJsx.default
                }
                ButtonRoundedLgSecondaryTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryTrailingIconHTML.default
                    : ButtonRoundedLgSecondaryTrailingIconJsx.default
                }
                ButtonRoundedLgSecondaryBadge={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryBadgeHTML.default
                    : ButtonRoundedLgSecondaryBadgeJsx.default
                }
                ButtonRoundedLgSecondaryIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryIconHTML.default
                    : ButtonRoundedLgSecondaryIconJsx.default
                }
                ButtonRoundedLgSecondaryAnimated={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedLgSecondaryAnimatedHTML.default
                    : ButtonRoundedLgSecondaryAnimatedJsx.default
                }
                ButtonRoundedBaseSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryBasicHTML.default
                    : ButtonRoundedBaseSecondaryBasicJsx.default
                }
                ButtonRoundedBaseSecondaryElevated={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryElevatedHTML.default
                    : ButtonRoundedBaseSecondaryElevatedJsx.default
                }
                ButtonRoundedBaseSecondaryDisabled={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryDisabledHTML.default
                    : ButtonRoundedBaseSecondaryDisabledJsx.default
                }
                ButtonRoundedBaseSecondaryLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryLeadingIconHTML.default
                    : ButtonRoundedBaseSecondaryLeadingIconJsx.default
                }
                ButtonRoundedBaseSecondaryTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryTrailingIconHTML.default
                    : ButtonRoundedBaseSecondaryTrailingIconJsx.default
                }
                ButtonRoundedBaseSecondaryBadge={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryBadgeHTML.default
                    : ButtonRoundedBaseSecondaryBadgeJsx.default
                }
                ButtonRoundedBaseSecondaryIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryIconHTML.default
                    : ButtonRoundedBaseSecondaryIconJsx.default
                }
                ButtonRoundedBaseSecondaryAnimated={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedBaseSecondaryAnimatedHTML.default
                    : ButtonRoundedBaseSecondaryAnimatedJsx.default
                }
                ButtonRoundedSmSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryBasicHTML.default
                    : ButtonRoundedSmSecondaryBasicJsx.default
                }
                ButtonRoundedSmSecondaryElevated={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryElevatedHTML.default
                    : ButtonRoundedSmSecondaryElevatedJsx.default
                }
                ButtonRoundedSmSecondaryDisabled={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryDisabledHTML.default
                    : ButtonRoundedSmSecondaryDisabledJsx.default
                }
                ButtonRoundedSmSecondaryLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryLeadingIconHTML.default
                    : ButtonRoundedSmSecondaryLeadingIconJsx.default
                }
                ButtonRoundedSmSecondaryTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryTrailingIconHTML.default
                    : ButtonRoundedSmSecondaryTrailingIconJsx.default
                }
                ButtonRoundedSmSecondaryBadge={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryBadgeHTML.default
                    : ButtonRoundedSmSecondaryBadgeJsx.default
                }
                ButtonRoundedSmSecondaryIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryIconHTML.default
                    : ButtonRoundedSmSecondaryIconJsx.default
                }
                ButtonRoundedSmSecondaryAnimated={
                  activeTabs[1].active_tab === 1
                    ? ButtonRoundedSmSecondaryAnimatedHTML.default
                    : ButtonRoundedSmSecondaryAnimatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="PrimaryRoundedButtons">Rounded Full Primary Buttons</h3>

          <PreviewBlock
            id="PrimaryRoundedButtons"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonRoundedFullLgPrimaryBasicHTML.default}
            JsxComponent={ButtonRoundedFullLgPrimaryBasicJsx.default}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <ButtonsAllPrimaryRoundedPreview
                ButtonRoundedFullLgPrimaryBasic={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryBasicHTML.default
                    : ButtonRoundedFullLgPrimaryBasicJsx.default
                }
                ButtonRoundedFullLgPrimaryElevated={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryElevatedHTML.default
                    : ButtonRoundedFullLgPrimaryElevatedJsx.default
                }
                ButtonRoundedFullLgPrimaryDisabled={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryDisabledHTML.default
                    : ButtonRoundedFullLgPrimaryDisabledJsx.default
                }
                ButtonRoundedFullLgPrimaryLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryLeadingIconHTML.default
                    : ButtonRoundedFullLgPrimaryLeadingIconJsx.default
                }
                ButtonRoundedFullLgPrimaryTrailingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryTrailingIconHTML.default
                    : ButtonRoundedFullLgPrimaryTrailingIconJsx.default
                }
                ButtonRoundedFullLgPrimaryBadge={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryBadgeHTML.default
                    : ButtonRoundedFullLgPrimaryBadgeJsx.default
                }
                ButtonRoundedFullLgPrimaryIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryIconHTML.default
                    : ButtonRoundedFullLgPrimaryIconJsx.default
                }
                ButtonRoundedFullLgPrimaryAnimated={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullLgPrimaryAnimatedHTML.default
                    : ButtonRoundedFullLgPrimaryAnimatedJsx.default
                }
                ButtonRoundedFullBasePrimaryBasic={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryBasicHTML.default
                    : ButtonRoundedFullBasePrimaryBasicJsx.default
                }
                ButtonRoundedFullBasePrimaryElevated={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryElevatedHTML.default
                    : ButtonRoundedFullBasePrimaryElevatedJsx.default
                }
                ButtonRoundedFullBasePrimaryDisabled={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryDisabledHTML.default
                    : ButtonRoundedFullBasePrimaryDisabledJsx.default
                }
                ButtonRoundedFullBasePrimaryLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryLeadingIconHTML.default
                    : ButtonRoundedFullBasePrimaryLeadingIconJsx.default
                }
                ButtonRoundedFullBasePrimaryTrailingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryTrailingIconHTML.default
                    : ButtonRoundedFullBasePrimaryTrailingIconJsx.default
                }
                ButtonRoundedFullBasePrimaryBadge={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryBadgeHTML.default
                    : ButtonRoundedFullBasePrimaryBadgeJsx.default
                }
                ButtonRoundedFullBasePrimaryIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryIconHTML.default
                    : ButtonRoundedFullBasePrimaryIconJsx.default
                }
                ButtonRoundedFullBasePrimaryAnimated={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullBasePrimaryAnimatedHTML.default
                    : ButtonRoundedFullBasePrimaryAnimatedJsx.default
                }
                ButtonRoundedFullSmPrimaryBasic={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryBasicHTML.default
                    : ButtonRoundedFullSmPrimaryBasicJsx.default
                }
                ButtonRoundedFullSmPrimaryElevated={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryElevatedHTML.default
                    : ButtonRoundedFullSmPrimaryElevatedJsx.default
                }
                ButtonRoundedFullSmPrimaryDisabled={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryDisabledHTML.default
                    : ButtonRoundedFullSmPrimaryDisabledJsx.default
                }
                ButtonRoundedFullSmPrimaryLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryLeadingIconHTML.default
                    : ButtonRoundedFullSmPrimaryLeadingIconJsx.default
                }
                ButtonRoundedFullSmPrimaryTrailingIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryTrailingIconHTML.default
                    : ButtonRoundedFullSmPrimaryTrailingIconJsx.default
                }
                ButtonRoundedFullSmPrimaryBadge={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryBadgeHTML.default
                    : ButtonRoundedFullSmPrimaryBadgeJsx.default
                }
                ButtonRoundedFullSmPrimaryIcon={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryIconHTML.default
                    : ButtonRoundedFullSmPrimaryIconJsx.default
                }
                ButtonRoundedFullSmPrimaryAnimated={
                  activeTabs[2].active_tab === 1
                    ? ButtonRoundedFullSmPrimaryAnimatedHTML.default
                    : ButtonRoundedFullSmPrimaryAnimatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="SecondaryRoundedButtons">Rounded Full Secondary Buttons</h3>

          <PreviewBlock
            id="SecondaryRoundedButtons"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonRoundedFullLgSecondaryBasicHTML.default}
            JsxComponent={ButtonRoundedFullLgSecondaryBasicJsx.default}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <ButtonsAllSecondaryRoundedPreview
                ButtonRoundedFullLgSecondaryBasic={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryBasicHTML.default
                    : ButtonRoundedFullLgSecondaryBasicJsx.default
                }
                ButtonRoundedFullLgSecondaryElevated={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryElevatedHTML.default
                    : ButtonRoundedFullLgSecondaryElevatedJsx.default
                }
                ButtonRoundedFullLgSecondaryDisabled={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryDisabledHTML.default
                    : ButtonRoundedFullLgSecondaryDisabledJsx.default
                }
                ButtonRoundedFullLgSecondaryLeadingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryLeadingIconHTML.default
                    : ButtonRoundedFullLgSecondaryLeadingIconJsx.default
                }
                ButtonRoundedFullLgSecondaryTrailingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryTrailingIconHTML.default
                    : ButtonRoundedFullLgSecondaryTrailingIconJsx.default
                }
                ButtonRoundedFullLgSecondaryBadge={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryBadgeHTML.default
                    : ButtonRoundedFullLgSecondaryBadgeJsx.default
                }
                ButtonRoundedFullLgSecondaryIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryIconHTML.default
                    : ButtonRoundedFullLgSecondaryIconJsx.default
                }
                ButtonRoundedFullLgSecondaryAnimated={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullLgSecondaryAnimatedHTML.default
                    : ButtonRoundedFullLgSecondaryAnimatedJsx.default
                }
                ButtonRoundedFullBaseSecondaryBasic={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryBasicHTML.default
                    : ButtonRoundedFullBaseSecondaryBasicJsx.default
                }
                ButtonRoundedFullBaseSecondaryElevated={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryElevatedHTML.default
                    : ButtonRoundedFullBaseSecondaryElevatedJsx.default
                }
                ButtonRoundedFullBaseSecondaryDisabled={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryDisabledHTML.default
                    : ButtonRoundedFullBaseSecondaryDisabledJsx.default
                }
                ButtonRoundedFullBaseSecondaryLeadingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryLeadingIconHTML.default
                    : ButtonRoundedFullBaseSecondaryLeadingIconJsx.default
                }
                ButtonRoundedFullBaseSecondaryTrailingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryTrailingIconHTML.default
                    : ButtonRoundedFullBaseSecondaryTrailingIconJsx.default
                }
                ButtonRoundedFullBaseSecondaryBadge={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryBadgeHTML.default
                    : ButtonRoundedFullBaseSecondaryBadgeJsx.default
                }
                ButtonRoundedFullBaseSecondaryIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryIconHTML.default
                    : ButtonRoundedFullBaseSecondaryIconJsx.default
                }
                ButtonRoundedFullBaseSecondaryAnimated={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullBaseSecondaryAnimatedHTML.default
                    : ButtonRoundedFullBaseSecondaryAnimatedJsx.default
                }
                ButtonRoundedFullSmSecondaryBasic={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryBasicHTML.default
                    : ButtonRoundedFullSmSecondaryBasicJsx.default
                }
                ButtonRoundedFullSmSecondaryElevated={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryElevatedHTML.default
                    : ButtonRoundedFullSmSecondaryElevatedJsx.default
                }
                ButtonRoundedFullSmSecondaryDisabled={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryDisabledHTML.default
                    : ButtonRoundedFullSmSecondaryDisabledJsx.default
                }
                ButtonRoundedFullSmSecondaryLeadingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryLeadingIconHTML.default
                    : ButtonRoundedFullSmSecondaryLeadingIconJsx.default
                }
                ButtonRoundedFullSmSecondaryTrailingIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryTrailingIconHTML.default
                    : ButtonRoundedFullSmSecondaryTrailingIconJsx.default
                }
                ButtonRoundedFullSmSecondaryBadge={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryBadgeHTML.default
                    : ButtonRoundedFullSmSecondaryBadgeJsx.default
                }
                ButtonRoundedFullSmSecondaryIcon={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryIconHTML.default
                    : ButtonRoundedFullSmSecondaryIconJsx.default
                }
                ButtonRoundedFullSmSecondaryAnimated={
                  activeTabs[3].active_tab === 1
                    ? ButtonRoundedFullSmSecondaryAnimatedHTML.default
                    : ButtonRoundedFullSmSecondaryAnimatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="OutlineButtons">Outline Buttons</h3>

          <PreviewBlock
            id="OutlineButtons"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonRoundedLgOutlineBasicHTML.default}
            JsxComponent={ButtonRoundedLgOutlineBasicJsx.default}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <ButtonsAllOutlinePreview
                ButtonRoundedLgOutlineBasic={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineBasicHTML.default
                    : ButtonRoundedLgOutlineBasicJsx.default
                }
                ButtonRoundedLgOutlineElevated={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineElevatedHTML.default
                    : ButtonRoundedLgOutlineElevatedJsx.default
                }
                ButtonRoundedLgOutlineDisabled={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineDisabledHTML.default
                    : ButtonRoundedLgOutlineDisabledJsx.default
                }
                ButtonRoundedLgOutlineLeadingIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineLeadingIconHTML.default
                    : ButtonRoundedLgOutlineLeadingIconJsx.default
                }
                ButtonRoundedLgOutlineTrailingIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineTrailingIconHTML.default
                    : ButtonRoundedLgOutlineTrailingIconJsx.default
                }
                ButtonRoundedLgOutlineBadge={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineBadgeHTML.default
                    : ButtonRoundedLgOutlineBadgeJsx.default
                }
                ButtonRoundedLgOutlineIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineIconHTML.default
                    : ButtonRoundedLgOutlineIconJsx.default
                }
                ButtonRoundedLgOutlineAnimated={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedLgOutlineAnimatedHTML.default
                    : ButtonRoundedLgOutlineAnimatedJsx.default
                }
                ButtonRoundedBaseOutlineBasic={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineBasicHTML.default
                    : ButtonRoundedBaseOutlineBasicJsx.default
                }
                ButtonRoundedBaseOutlineElevated={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineElevatedHTML.default
                    : ButtonRoundedBaseOutlineElevatedJsx.default
                }
                ButtonRoundedBaseOutlineDisabled={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineDisabledHTML.default
                    : ButtonRoundedBaseOutlineDisabledJsx.default
                }
                ButtonRoundedBaseOutlineLeadingIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineLeadingIconHTML.default
                    : ButtonRoundedBaseOutlineLeadingIconJsx.default
                }
                ButtonRoundedBaseOutlineTrailingIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineTrailingIconHTML.default
                    : ButtonRoundedBaseOutlineTrailingIconJsx.default
                }
                ButtonRoundedBaseOutlineBadge={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineBadgeHTML.default
                    : ButtonRoundedBaseOutlineBadgeJsx.default
                }
                ButtonRoundedBaseOutlineIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineIconHTML.default
                    : ButtonRoundedBaseOutlineIconJsx.default
                }
                ButtonRoundedBaseOutlineAnimated={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedBaseOutlineAnimatedHTML.default
                    : ButtonRoundedBaseOutlineAnimatedJsx.default
                }
                ButtonRoundedSmOutlineBasic={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineBasicHTML.default
                    : ButtonRoundedSmOutlineBasicJsx.default
                }
                ButtonRoundedSmOutlineElevated={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineElevatedHTML.default
                    : ButtonRoundedSmOutlineElevatedJsx.default
                }
                ButtonRoundedSmOutlineDisabled={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineDisabledHTML.default
                    : ButtonRoundedSmOutlineDisabledJsx.default
                }
                ButtonRoundedSmOutlineLeadingIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineLeadingIconHTML.default
                    : ButtonRoundedSmOutlineLeadingIconJsx.default
                }
                ButtonRoundedSmOutlineTrailingIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineTrailingIconHTML.default
                    : ButtonRoundedSmOutlineTrailingIconJsx.default
                }
                ButtonRoundedSmOutlineBadge={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineBadgeHTML.default
                    : ButtonRoundedSmOutlineBadgeJsx.default
                }
                ButtonRoundedSmOutlineIcon={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineIconHTML.default
                    : ButtonRoundedSmOutlineIconJsx.default
                }
                ButtonRoundedSmOutlineAnimated={
                  activeTabs[4].active_tab === 1
                    ? ButtonRoundedSmOutlineAnimatedHTML.default
                    : ButtonRoundedSmOutlineAnimatedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="LinkButtons">Link Buttons</h3>

          <PreviewBlock
            id="LinkButtons"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonRoundedLgLinkBasicHTML.default}
            JsxComponent={ButtonRoundedLgLinkBasicJsx.default}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <ButtonsAllLinkPreview
                ButtonRoundedLgLinkBasic={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedLgLinkBasicHTML.default
                    : ButtonRoundedLgLinkBasicJsx.default
                }
                ButtonRoundedLgLinkLeadingIcon={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedLgLinkLeadingIconHTML.default
                    : ButtonRoundedLgLinkLeadingIconJsx.default
                }
                ButtonRoundedLgLinkTrailingIcon={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedLgLinkTrailingIconHTML.default
                    : ButtonRoundedLgLinkTrailingIconJsx.default
                }
                ButtonRoundedBaseLinkBasic={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedBaseLinkBasicHTML.default
                    : ButtonRoundedBaseLinkBasicJsx.default
                }
                ButtonRoundedBaseLinkLeadingIcon={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedBaseLinkLeadingIconHTML.default
                    : ButtonRoundedBaseLinkLeadingIconJsx.default
                }
                ButtonRoundedBaseLinkTrailingIcon={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedBaseLinkTrailingIconHTML.default
                    : ButtonRoundedBaseLinkTrailingIconJsx.default
                }
                ButtonRoundedSmLinkBasic={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedSmLinkBasicHTML.default
                    : ButtonRoundedSmLinkBasicJsx.default
                }
                ButtonRoundedSmLinkLeadingIcon={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedSmLinkLeadingIconHTML.default
                    : ButtonRoundedSmLinkLeadingIconJsx.default
                }
                ButtonRoundedSmLinkTrailingIcon={
                  activeTabs[5].active_tab === 1
                    ? ButtonRoundedSmLinkTrailingIconHTML.default
                    : ButtonRoundedSmLinkTrailingIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AppButtons">App Buttons</h3>

          <PreviewBlock
            id="AppButtons"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonRoundedLgAppPrimaryAppleHTML.default}
            JsxComponent={ButtonRoundedLgAppPrimaryAppleJsx.default}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <ButtonsAllAppPreview
                ButtonRoundedLgAppPrimaryApple={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedLgAppPrimaryAppleHTML.default
                    : ButtonRoundedLgAppPrimaryAppleJsx.default
                }
                ButtonRoundedLgAppPrimaryGoogle={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedLgAppPrimaryGoogleHTML.default
                    : ButtonRoundedLgAppPrimaryGoogleJsx.default
                }
                ButtonRoundedLgAppBasicApple={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedLgAppBasicAppleHTML.default
                    : ButtonRoundedLgAppBasicAppleJsx.default
                }
                ButtonRoundedLgAppBasicGoogle={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedLgAppBasicGoogleHTML.default
                    : ButtonRoundedLgAppBasicGoogleJsx.default
                }
                ButtonRoundedLgAppOutlineApple={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedLgAppOutlineAppleHTML.default
                    : ButtonRoundedLgAppOutlineAppleJsx.default
                }
                ButtonRoundedLgAppOutlineGoogle={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedLgAppOutlineGoogleHTML.default
                    : ButtonRoundedLgAppOutlineGoogleJsx.default
                }
                ButtonRoundedBaseAppPrimaryApple={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedBaseAppPrimaryAppleHTML.default
                    : ButtonRoundedBaseAppPrimaryAppleJsx.default
                }
                ButtonRoundedBaseAppPrimaryGoogle={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedBaseAppPrimaryGoogleHTML.default
                    : ButtonRoundedBaseAppPrimaryGoogleJsx.default
                }
                ButtonRoundedBaseAppBasicApple={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedBaseAppBasicAppleHTML.default
                    : ButtonRoundedBaseAppBasicAppleJsx.default
                }
                ButtonRoundedBaseAppBasicGoogle={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedBaseAppBasicGoogleHTML.default
                    : ButtonRoundedBaseAppBasicGoogleJsx.default
                }
                ButtonRoundedBaseAppOutlineApple={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedBaseAppOutlineAppleHTML.default
                    : ButtonRoundedBaseAppOutlineAppleJsx.default
                }
                ButtonRoundedBaseAppOutlineGoogle={
                  activeTabs[6].active_tab === 1
                    ? ButtonRoundedBaseAppOutlineGoogleHTML.default
                    : ButtonRoundedBaseAppOutlineGoogleJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>
        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Buttons communicate actions that users can take. They are typically
            placed throughout your UI, in places like:
          </p>
          <ul>
            <li>Dialogs</li>
            <li>Modal windows</li>
            <li>Forms</li>
            <li>Cards</li>
            <li>Toolbars</li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Buttons come in 6 styles (primary, secondary, primary rounded,
            secondary rounded, outline and link) They can contain text, leading
            or trailing icons and even a notification badge. All variations come
            in 3 sizes:
          </p>
          <ul>
            <li>
              <strong>Small:</strong> 2em height (32px), 1rem (16px) horizontal
              padding and 0.75rem (12px) font size. Icons on small buttons are
              1rem (16px) wide and tall and have a 0.5rem (8px) distance from
              the text.
            </li>
            <li>
              <strong>Base:</strong> 2.5em height (40px), 1.25rem (20px)
              horizontal padding and 0.875rem (14px) font size. Icons on base
              buttons are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
            <li>
              <strong>Large:</strong> 3em height (48px), 1.5rem (24px)
              horizontal padding and 0.875rem (14px) font size. Icons on large
              buttons are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
          </ul>
           
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              A <code>&lt;button&gt;</code> tag does not need anything special
              to work. Use <code>&lt;button&gt;</code> when you can, but it is
              possible to use other elements (such as a link) as long as you
              add&nbsp;role=&quot;button&quot;
            </li>
            <li>
              In the case of icon-only buttons include a span with
              className=&ldquo;sr-only&quot; from screen readers and hence give
              more context to the button's purpose.
            </li>
            <li>
              If it contains an icon, use aria-label to describe the icon.
            </li>
            <li>
              If a link is used instead of a button element include a{" "}
              <code>role=&quot;button&quot;</code> role.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
