import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonAllLinkPreview(props) {
  const {
    ButtonRoundedLgLinkBasic,
    ButtonRoundedLgLinkLeadingIcon,
    ButtonRoundedLgLinkTrailingIcon,
  } = props

  const {
    ButtonRoundedBaseLinkBasic,
    ButtonRoundedBaseLinkLeadingIcon,
    ButtonRoundedBaseLinkTrailingIcon,
  } = props

  const {
    ButtonRoundedSmLinkBasic,
    ButtonRoundedSmLinkLeadingIcon,
    ButtonRoundedSmLinkTrailingIcon,
  } = props

  return (
    <>
      <div className="flex w-full flex-col items-start justify-center gap-20">
        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgLinkBasic}
            componentName="ButtonRoundedLgLinkBasic"
          >
            {/*    <!-- Component: Large link basic button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span>Link large</span>
            </button>
            {/*    <!-- End Large link basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseLinkBasic}
            componentName="ButtonRoundedBaseLinkBasic"
          >
            {/*    <!-- Component: Base link basic button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span>Link base</span>
            </button>
            {/*    <!-- End Base link basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmLinkBasic}
            componentName="ButtonRoundedSmLinkBasic"
          >
            {/*    <!-- Component: Small link basic button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span>Link small</span>
            </button>
            {/*    <!-- End Small link basic button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgLinkLeadingIcon}
            componentName="ButtonRoundedLgLinkLeadingIcon"
          >
            {/*    <!-- Component: Large link button with leading icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-77 desc-77"
                >
                  <title id="title-77">Icon title</title>
                  <desc id="desc-77">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large link button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseLinkLeadingIcon}
            componentName="ButtonRoundedBaseLinkLeadingIcon"
          >
            {/*    <!-- Component: Base link button with leading icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-79 desc-79"
                >
                  <title id="title-79">Icon title</title>
                  <desc id="desc-79">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base link button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmLinkLeadingIcon}
            componentName="ButtonRoundedSmLinkLeadingIcon"
          >
            {/*    <!-- Component: Small link button with leading icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-81 desc-81"
                >
                  <title id="title-81">Icon title</title>
                  <desc id="desc-81">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small link button with leading icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgLinkTrailingIcon}
            componentName="ButtonRoundedLgLinkTrailingIcon"
          >
            {/*    <!-- Component: Large link button with trailing icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-78 desc-78"
                >
                  <title id="title-78">Icon title</title>
                  <desc id="desc-78">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large link button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseLinkTrailingIcon}
            componentName="ButtonRoundedBaseLinkTrailingIcon"
          >
            {/*    <!-- Component: Base link button with trailing icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-80 desc-80"
                >
                  <title id="title-80">Icon title</title>
                  <desc id="desc-80">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base link button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmLinkTrailingIcon}
            componentName="ButtonRoundedSmLinkTrailingIcon"
          >
            {/*    <!-- Component: Small link button with trailing icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none disabled:hover:bg-transparent">
              <span>Trail icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-82 desc-82"
                >
                  <title id="title-82">Icon title</title>
                  <desc id="desc-82">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small link button with trailing icon  --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
