import React from "react"

export default function HeroButtonComponent() {
  return (
    <div className="flex flex-col items-center gap-10 rounded-md sm:flex-row">
      {/*<!-- Primary lg button --> */}
      <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 hover:stroke-emerald-500 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
        <span>Primary button</span>
      </button>

      {/* <!-- Secondary base button --> */}
      <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
        <span>Secondary button</span>
      </button>

      {/* <!-- Outline sm button --> */}
      <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:border-emerald-700 focus:text-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
        <span>Outline button</span>
      </button>
    </div>
  )
}
