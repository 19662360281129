import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonAllPrimaryRoundedPreview(props) {
  const {
    ButtonRoundedFullLgPrimaryBasic,
    ButtonRoundedFullLgPrimaryElevated,
    ButtonRoundedFullLgPrimaryDisabled,
    ButtonRoundedFullLgPrimaryLeadingIcon,
    ButtonRoundedFullLgPrimaryTrailingIcon,
    ButtonRoundedFullLgPrimaryBadge,
    ButtonRoundedFullLgPrimaryIcon,
    ButtonRoundedFullLgPrimaryAnimated,
  } = props

  const {
    ButtonRoundedFullBasePrimaryBasic,
    ButtonRoundedFullBasePrimaryElevated,
    ButtonRoundedFullBasePrimaryDisabled,
    ButtonRoundedFullBasePrimaryLeadingIcon,
    ButtonRoundedFullBasePrimaryTrailingIcon,
    ButtonRoundedFullBasePrimaryBadge,
    ButtonRoundedFullBasePrimaryIcon,
    ButtonRoundedFullBasePrimaryAnimated,
  } = props

  const {
    ButtonRoundedFullSmPrimaryBasic,
    ButtonRoundedFullSmPrimaryElevated,
    ButtonRoundedFullSmPrimaryDisabled,
    ButtonRoundedFullSmPrimaryLeadingIcon,
    ButtonRoundedFullSmPrimaryTrailingIcon,
    ButtonRoundedFullSmPrimaryBadge,
    ButtonRoundedFullSmPrimaryIcon,
    ButtonRoundedFullSmPrimaryAnimated,
  } = props

  return (
    <>
      <div className="flex w-full flex-col items-start justify-center gap-20">
        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryBasic}
            componentName="ButtonRoundedFullLgPrimaryBasic"
          >
            {/*    <!-- Component: Large primary basic button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Large button</span>
            </button>
            {/*    <!-- End Large primary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryBasic}
            componentName="ButtonRoundedFullBasePrimaryBasic"
          >
            {/*    <!-- Component: Base primary basic button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Base button</span>
            </button>
            {/*    <!-- End Base primary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryBasic}
            componentName="ButtonRoundedFullSmPrimaryBasic"
          >
            {/*    <!-- Component: Small primary basic button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Small button</span>
            </button>
            {/*    <!-- End Small primary basic button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryElevated}
            componentName="ButtonRoundedFullLgPrimaryElevated"
          >
            {/*    <!-- Component: Large primary elevated button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white shadow-lg shadow-emerald-200 transition duration-300 hover:bg-emerald-600 hover:shadow-md hover:shadow-emerald-200 focus:bg-emerald-700 focus:shadow-md focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Elevated large</span>
            </button>
            {/*    <!-- End Large primary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryElevated}
            componentName="ButtonRoundedFullBasePrimaryElevated"
          >
            {/*    <!-- Component: Base primary elevated button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white shadow-md shadow-emerald-200 transition duration-300 hover:bg-emerald-600 hover:shadow-sm hover:shadow-emerald-200 focus:bg-emerald-700 focus:shadow-sm focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Elevated base</span>
            </button>
            {/*    <!-- End Base primary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryElevated}
            componentName="ButtonRoundedFullSmPrimaryElevated"
          >
            {/*    <!-- Component: Small primary elevated button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white shadow-md shadow-emerald-200 transition duration-300 hover:bg-emerald-600 hover:shadow-sm hover:shadow-emerald-200 focus:bg-emerald-700 focus:shadow-sm focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Elevated small</span>
            </button>
            {/*    <!-- End Small primary elevated button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryDisabled}
            componentName="ButtonRoundedFullLgPrimaryDisabled"
          >
            {/*    <!-- Component: Large primary disabled button --> */}
            <button
              disabled
              className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
            >
              <span>Disabled large</span>
            </button>
            {/*    <!-- End Large primary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryDisabled}
            componentName="ButtonRoundedFullBasePrimaryDisabled"
          >
            {/*    <!-- Component: Base primary disabled button --> */}
            <button
              disabled
              className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
            >
              <span>Disabled base</span>
            </button>
            {/*    <!-- End Base primary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryDisabled}
            componentName="ButtonRoundedFullSmPrimaryDisabled"
          >
            {/*    <!-- Component: Small primary disabled button --> */}
            <button
              disabled
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
            >
              <span>Disabled small</span>
            </button>
            {/*    <!-- End Small primary disabled button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryLeadingIcon}
            componentName="ButtonRoundedFullLgPrimaryLeadingIcon"
          >
            {/*    <!-- Component: Large primary button with leading icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-31 desc-31"
                >
                  <title id="title-31">Icon title</title>
                  <desc id="desc-31">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryLeadingIcon}
            componentName="ButtonRoundedFullBasePrimaryLeadingIcon"
          >
            {/*    <!-- Component: Base primary button with leading icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-37 desc-37"
                >
                  <title id="title-37">Icon title</title>
                  <desc id="desc-37">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryLeadingIcon}
            componentName="ButtonRoundedFullSmPrimaryLeadingIcon"
          >
            {/*    <!-- Component: Small primary button with leading icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-42 desc-42"
                >
                  <title id="title-42">Icon title</title>
                  <desc id="desc-42">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with leading icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryTrailingIcon}
            componentName="ButtonRoundedFullLgPrimaryTrailingIcon"
          >
            {/*    <!-- Component: Large primary button with trailing icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-32 desc-32"
                >
                  <title id="title-32">Icon title</title>
                  <desc id="desc-32">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryTrailingIcon}
            componentName="ButtonRoundedFullBasePrimaryTrailingIcon"
          >
            {/*    <!-- Component: Base primary button with trailing icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-38 desc-38"
                >
                  <title id="title-38">Icon title</title>
                  <desc id="desc-38">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryTrailingIcon}
            componentName="ButtonRoundedFullSmPrimaryTrailingIcon"
          >
            {/*    <!-- Component: Small primary button with trailing icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-43 desc-43"
                >
                  <title id="title-43">Icon title</title>
                  <desc id="desc-43">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with trailing icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryBadge}
            componentName="ButtonRoundedFullLgPrimaryBadge"
          >
            {/*    <!-- Component: Large primary button with badge  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Icon & Badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-33 desc-33"
                >
                  <title id="title-33">Icon title</title>
                  <desc id="desc-33">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Large primary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryBadge}
            componentName="ButtonRoundedFullBasePrimaryBadge"
          >
            {/*    <!-- Component: Base primary button with badge  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-39 desc-39"
                >
                  <title id="title-39">Icon title</title>
                  <desc id="desc-39">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Base primary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryBadge}
            componentName="ButtonRoundedFullSmPrimaryBadge"
          >
            {/*    <!-- Component: Small primary button with badge  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-44 desc-44"
                >
                  <title id="title-44">Icon title</title>
                  <desc id="desc-44">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-0.5 -right-0.5 rounded-full bg-pink-500 p-1">
                  <span className="sr-only">8 new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Small primary button with badge  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryIcon}
            componentName="ButtonRoundedFullLgPrimaryIcon"
          >
            {/*    <!-- Component: Large primary button with icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 self-center whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-6">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-34 desc-34"
                >
                  <title id="title-34">Icon title</title>
                  <desc id="desc-34">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryIcon}
            componentName="ButtonRoundedFullBasePrimaryIcon"
          >
            {/*    <!-- Component: Base primary button with icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 self-center whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-5">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-40 desc-40"
                >
                  <title id="title-40">Icon title</title>
                  <desc id="desc-40">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryIcon}
            componentName="ButtonRoundedFullSmPrimaryIcon"
          >
            {/*    <!-- Component: Small primary button with icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 self-center whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-4">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-45 desc-45"
                >
                  <title id="title-45">Icon title</title>
                  <desc id="desc-45">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with icon  --> */}
          </CopyComponent>
        </div>
        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgPrimaryAnimated}
            componentName="ButtonRoundedFullLgPrimaryAnimated"
          >
            {/*    <!-- Component: Large primary button with animation  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-36 desc-36"
                >
                  <title id="title-36">Icon title</title>
                  <desc id="desc-36">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Large primary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBasePrimaryAnimated}
            componentName="ButtonRoundedFullBasePrimaryAnimated"
          >
            {/*    <!-- Component: Base primary button with animation  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-5">
                <svg
                  className="h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-41 desc-41"
                >
                  <title id="title-41">Icon title</title>
                  <desc id="desc-41">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Base primary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmPrimaryAnimated}
            componentName="ButtonRoundedFullSmPrimaryAnimated"
          >
            {/*    <!-- Component: Small primary button with animation  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded-full bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-4 w-4 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-46 desc-46"
                >
                  <title id="title-46">Icon title</title>
                  <desc id="desc-46">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Small primary button with animation  --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
