import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonAllSecondaryRoundedPreview(props) {
  const {
    ButtonRoundedFullLgSecondaryBasic,
    ButtonRoundedFullLgSecondaryElevated,
    ButtonRoundedFullLgSecondaryDisabled,
    ButtonRoundedFullLgSecondaryLeadingIcon,
    ButtonRoundedFullLgSecondaryTrailingIcon,
    ButtonRoundedFullLgSecondaryBadge,
    ButtonRoundedFullLgSecondaryIcon,
    ButtonRoundedFullLgSecondaryAnimated,
  } = props

  const {
    ButtonRoundedFullBaseSecondaryBasic,
    ButtonRoundedFullBaseSecondaryElevated,
    ButtonRoundedFullBaseSecondaryDisabled,
    ButtonRoundedFullBaseSecondaryLeadingIcon,
    ButtonRoundedFullBaseSecondaryTrailingIcon,
    ButtonRoundedFullBaseSecondaryBadge,
    ButtonRoundedFullBaseSecondaryIcon,
    ButtonRoundedFullBaseSecondaryAnimated,
  } = props

  const {
    ButtonRoundedFullSmSecondaryBasic,
    ButtonRoundedFullSmSecondaryElevated,
    ButtonRoundedFullSmSecondaryDisabled,
    ButtonRoundedFullSmSecondaryLeadingIcon,
    ButtonRoundedFullSmSecondaryTrailingIcon,
    ButtonRoundedFullSmSecondaryBadge,
    ButtonRoundedFullSmSecondaryIcon,
    ButtonRoundedFullSmSecondaryAnimated,
  } = props
  return (
    <>
      <div className="flex w-full flex-col items-start justify-center gap-20">
        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryBasic}
            componentName="ButtonRoundedFullLgSecondaryBasic"
          >
            {/*    <!-- Component: Large secondary basic button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Large button</span>
            </button>
            {/*    <!-- End Large secondary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryBasic}
            componentName="ButtonRoundedFullBaseSecondaryBasic"
          >
            {/*    <!-- Component: Base secondary basic button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Base button</span>
            </button>
            {/*    <!-- End Base secondary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryBasic}
            componentName="ButtonRoundedFullSmSecondaryBasic"
          >
            {/*    <!-- Component: Small secondary basic button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Small button</span>
            </button>
            {/*    <!-- End Small secondary basic button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryElevated}
            componentName="ButtonRoundedFullLgSecondaryElevated"
          >
            {/*    <!-- Component: Large secondary elevated button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 shadow-lg shadow-emerald-100 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-200 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Elevated large</span>
            </button>
            {/*    <!-- End Large secondary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryElevated}
            componentName="ButtonRoundedFullBaseSecondaryElevated"
          >
            {/*    <!-- Component: Base secondary elevated button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 shadow-md shadow-emerald-100 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-200 focus:bg-emerald-200 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-100 disabled:bg-emerald-100 disabled:shadow-none">
              <span>Elevated base</span>
            </button>
            {/*    <!-- End Base secondary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryElevated}
            componentName="ButtonRoundedFullSmSecondaryElevated"
          >
            {/*    <!-- Component: Small secondary elevated button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 shadow-md shadow-emerald-100 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-200 focus:bg-emerald-200 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Elevated small</span>
            </button>
            {/*    <!-- End Small secondary elevated button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryDisabled}
            componentName="ButtonRoundedFullLgSecondaryDisabled"
          >
            {/*    <!-- Component: Large secondary disabled button --> */}
            <button
              disabled
              className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none"
            >
              <span>Disabled large</span>
            </button>
            {/*    <!-- End Large secondary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryDisabled}
            componentName="ButtonRoundedFullBaseSecondaryDisabled"
          >
            {/*    <!-- Component: Base secondary disabled button --> */}
            <button
              disabled
              className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-100 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none"
            >
              <span>Disabled base</span>
            </button>
            {/*    <!-- End Base secondary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryDisabled}
            componentName="ButtonRoundedFullSmSecondaryDisabled"
          >
            {/*    <!-- Component: Small secondary disabled button --> */}
            <button
              disabled
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none"
            >
              <span>Disabled small</span>
            </button>
            {/*    <!-- End Small secondary disabled button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryLeadingIcon}
            componentName="ButtonRoundedFullLgSecondaryLeadingIcon"
          >
            {/*    <!-- Component: Large secondary button with leading icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-47 desc-47"
                >
                  <title id="title-47">Icon title</title>
                  <desc id="desc-47">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryLeadingIcon}
            componentName="ButtonRoundedFullBaseSecondaryLeadingIcon"
          >
            {/*    <!-- Component: Base secondary button with leading icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="order-2">lead icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-52 desc-52"
                >
                  <title id="title-52">Icon title</title>
                  <desc id="desc-52">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryLeadingIcon}
            componentName="ButtonRoundedFullSmSecondaryLeadingIcon"
          >
            {/*    <!-- Component: Small secondary button with leading icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-57 desc-57"
                >
                  <title id="title-57">Icon title</title>
                  <desc id="desc-57">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with leading icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryTrailingIcon}
            componentName="ButtonRoundedFullLgSecondaryTrailingIcon"
          >
            {/*    <!-- Component: Large secondary button with trailing icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-48 desc-48"
                >
                  <title id="title-48">Icon title</title>
                  <desc id="desc-48">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryTrailingIcon}
            componentName="ButtonRoundedFullBaseSecondaryTrailingIcon"
          >
            {/*    <!-- Component: Base secondary button with trailing icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-53 desc-53"
                >
                  <title id="title-53">Icon title</title>
                  <desc id="desc-53">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryTrailingIcon}
            componentName="ButtonRoundedFullSmSecondaryTrailingIcon"
          >
            {/*    <!-- Component: Small secondary button with trailing icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-58 desc-58"
                >
                  <title id="title-58">Icon title</title>
                  <desc id="desc-58">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with trailing icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryBadge}
            componentName="ButtonRoundedFullLgSecondaryBadge"
          >
            {/*    <!-- Component: Large secondary button with badge  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Icon & Badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-49 desc-49"
                >
                  <title id="title-49">Icon title</title>
                  <desc id="desc-49">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Large secondary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryBadge}
            componentName="ButtonRoundedFullBaseSecondaryBadge"
          >
            {/*    <!-- Component: Base secondary button with badge  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-54 desc-54"
                >
                  <title id="title-54">Icon title</title>
                  <desc id="desc-54">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Base secondary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryBadge}
            componentName="ButtonRoundedFullSmSecondaryBadge"
          >
            {/*    <!-- Component: Small secondary button with badge  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-59 desc-59"
                >
                  <title id="title-59">Icon title</title>
                  <desc id="desc-59">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-0.5 -right-0.5 rounded-full bg-pink-500 p-1">
                  <span className="sr-only">8 new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Small secondary button with badge  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryIcon}
            componentName="ButtonRoundedFullLgSecondaryIcon"
          >
            {/*    <!-- Component: Large secondary button with icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 self-center justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="relative only:-mx-6">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-50 desc-50"
                >
                  <title id="title-50">Icon title</title>
                  <desc id="desc-50">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryIcon}
            componentName="ButtonRoundedFullBaseSecondaryIcon"
          >
            {/*    <!-- Component: Base secondary button with icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 self-center justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="relative only:-mx-5">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-55 desc-55"
                >
                  <title id="title-55">Icon title</title>
                  <desc id="desc-55">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryIcon}
            componentName="ButtonRoundedFullSmSecondaryIcon"
          >
            {/*    <!-- Component: Small secondary button with icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 self-center justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="relative only:-mx-4">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-60 desc-60"
                >
                  <title id="title-60">Icon title</title>
                  <desc id="desc-60">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullLgSecondaryAnimated}
            componentName="ButtonRoundedFullLgSecondaryAnimated"
          >
            {/*    <!-- Component: Large secondary button with animation  --> */}
            <button className="group inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-5 w-5 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-51 desc-51"
                >
                  <title id="title-51">Icon title</title>
                  <desc id="desc-51">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullBaseSecondaryAnimated}
            componentName="ButtonRoundedFullBaseSecondaryAnimated"
          >
            {/*    <!-- Component: Base secondary button with animation  --> */}
            <button className="group inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-5">
                <svg
                  className="h-5 w-5 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-56 desc-56"
                >
                  <title id="title-56">Icon title</title>
                  <desc id="desc-56">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedFullSmSecondaryAnimated}
            componentName="ButtonRoundedFullSmSecondaryAnimated"
          >
            {/*    <!-- Component: Small secondary button with animation  --> */}
            <button className="group inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-4 w-4 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-61 desc-61"
                >
                  <title id="title-61">Icon title</title>
                  <desc id="desc-61">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with animation  --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
