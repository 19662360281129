import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonAllOutlinePreview(props) {
  const {
    ButtonRoundedLgOutlineBasic,
    ButtonRoundedLgOutlineElevated,
    ButtonRoundedLgOutlineDisabled,
    ButtonRoundedLgOutlineLeadingIcon,
    ButtonRoundedLgOutlineTrailingIcon,
    ButtonRoundedLgOutlineBadge,
    ButtonRoundedLgOutlineIcon,
    ButtonRoundedLgOutlineAnimated,
  } = props

  const {
    ButtonRoundedBaseOutlineBasic,
    ButtonRoundedBaseOutlineElevated,
    ButtonRoundedBaseOutlineDisabled,
    ButtonRoundedBaseOutlineLeadingIcon,
    ButtonRoundedBaseOutlineTrailingIcon,
    ButtonRoundedBaseOutlineBadge,
    ButtonRoundedBaseOutlineIcon,
    ButtonRoundedBaseOutlineAnimated,
  } = props

  const {
    ButtonRoundedSmOutlineBasic,
    ButtonRoundedSmOutlineElevated,
    ButtonRoundedSmOutlineDisabled,
    ButtonRoundedSmOutlineLeadingIcon,
    ButtonRoundedSmOutlineTrailingIcon,
    ButtonRoundedSmOutlineBadge,
    ButtonRoundedSmOutlineIcon,
    ButtonRoundedSmOutlineAnimated,
  } = props

  return (
    <>
      <div className="flex w-full flex-col items-start justify-center gap-20">
        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineBasic}
            componentName="ButtonRoundedLgOutlineBasic"
          >
            {/*    <!-- Component: Large outline basic button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Large button</span>
            </button>
            {/*    <!-- End Large outline basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineBasic}
            componentName="ButtonRoundedBaseOutlineBasic"
          >
            {/*    <!-- Component: Base outline basic button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Base button</span>
            </button>
            {/*    <!-- End Base outline basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineBasic}
            componentName="ButtonRoundedSmOutlineBasic"
          >
            {/*    <!-- Component: Small outline basic button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Small button</span>
            </button>
            {/*    <!-- End Small outline basic button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineElevated}
            componentName="ButtonRoundedLgOutlineElevated"
          >
            {/*    <!-- Component: Large outline elevated button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 shadow-lg shadow-emerald-200 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-200 focus:border-emerald-700 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Elevated large</span>
            </button>
            {/*    <!-- End Large outline elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineElevated}
            componentName="ButtonRoundedBaseOutlineElevated"
          >
            {/*    <!-- Component: Base outline elevated button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 shadow-md shadow-emerald-200 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 hover:shadow-sm hover:shadow-emerald-200 focus:border-emerald-700 focus:text-emerald-700 focus:shadow-sm focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Elevated base</span>
            </button>
            {/*    <!-- End Base outline elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineElevated}
            componentName="ButtonRoundedSmOutlineElevated"
          >
            {/*    <!-- Component: Small outline elevated button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 shadow-md shadow-emerald-200 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 hover:shadow-sm hover:shadow-emerald-200 focus:border-emerald-700 focus:text-emerald-700 focus:shadow-sm focus:shadow-emerald-200 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Elevated small</span>
            </button>
            {/*    <!-- End Small outline elevated button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineDisabled}
            componentName="ButtonRoundedLgOutlineDisabled"
          >
            {/*    <!-- Component: Large outline disabled button --> */}
            <button
              disabled
              className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none"
            >
              <span>disabled large</span>
            </button>
            {/*    <!-- End Large outline disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineDisabled}
            componentName="ButtonRoundedBaseOutlineDisabled"
          >
            {/*    <!-- Component: Base outline disabled button --> */}
            <button
              disabled
              className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none"
            >
              <span>Disabled base</span>
            </button>
            {/*    <!-- End Base outline disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineDisabled}
            componentName="ButtonRoundedSmOutlineDisabled"
          >
            {/*    <!-- Component: Small outline disabled button --> */}
            <button
              disabled
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none"
            >
              <span>Disabled small</span>
            </button>
            {/*    <!-- End Small outline disabled button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineLeadingIcon}
            componentName="ButtonRoundedLgOutlineLeadingIcon"
          >
            {/*    <!-- Component: Large outline button with leading icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-62 desc-62"
                >
                  <title id="title-62">Icon title</title>
                  <desc id="desc-62">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large outline button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineLeadingIcon}
            componentName="ButtonRoundedBaseOutlineLeadingIcon"
          >
            {/*    <!-- Component: Base outline button with leading icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-67 desc-67"
                >
                  <title id="title-67">Icon title</title>
                  <desc id="desc-67">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base outline button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineLeadingIcon}
            componentName="ButtonRoundedSmOutlineLeadingIcon"
          >
            {/*    <!-- Component: Small outline button with leading icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-72 desc-72"
                >
                  <title id="title-72">Icon title</title>
                  <desc id="desc-72">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small outline button with leading icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineTrailingIcon}
            componentName="ButtonRoundedLgOutlineTrailingIcon"
          >
            {/*    <!-- Component: Large outline button with trailing icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-63 desc-63"
                >
                  <title id="title-63">Icon title</title>
                  <desc id="desc-63">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large outline button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineTrailingIcon}
            componentName="ButtonRoundedBaseOutlineTrailingIcon"
          >
            {/*    <!-- Component: Base outline button with trailing icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-68 desc-68"
                >
                  <title id="title-68">Icon title</title>
                  <desc id="desc-68">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base outline button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineTrailingIcon}
            componentName="ButtonRoundedSmOutlineTrailingIcon"
          >
            {/*    <!-- Component: Small outline button with trailing icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-73 desc-73"
                >
                  <title id="title-73">Icon title</title>
                  <desc id="desc-73">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small outline button with trailing icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineBadge}
            componentName="ButtonRoundedLgOutlineBadge"
          >
            {/*    <!-- Component: Large outline button with badge  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Icon & Badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-64 desc-64"
                >
                  <title id="title-64">Icon title</title>
                  <desc id="desc-64">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white hover:border-emerald-600">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Large outline button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineBadge}
            componentName="ButtonRoundedBaseOutlineBadge"
          >
            {/*    <!-- Component: Base outline button with badge  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-69 desc-69"
                >
                  <title id="title-69">Icon title</title>
                  <desc id="desc-69">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white hover:border-emerald-600">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Base outline button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineBadge}
            componentName="ButtonRoundedSmOutlineBadge"
          >
            {/*    <!-- Component: Small outline button with badge  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-74 desc-74"
                >
                  <title id="title-74">Icon title</title>
                  <desc id="desc-74">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-0.5 -right-0.5 rounded-full bg-pink-500 p-1">
                  <span className="sr-only">8 new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Small outline button with badge  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineIcon}
            componentName="ButtonRoundedLgOutlineIcon"
          >
            {/*    <!-- Component: Large outline button with icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 self-center whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-6">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-65 desc-65"
                >
                  <title id="title-65">Icon title</title>
                  <desc id="desc-65">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large outline button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineIcon}
            componentName="ButtonRoundedBaseOutlineIcon"
          >
            {/*    <!-- Component: Base outline button with icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 self-center whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-5">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-70 desc-70"
                >
                  <title id="title-70">Icon title</title>
                  <desc id="desc-70">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base outline button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineIcon}
            componentName="ButtonRoundedSmOutlineIcon"
          >
            {/*    <!-- Component: Small outline button with icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 self-center whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span className="relative only:-mx-4">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-75 desc-75"
                >
                  <title id="title-75">Icon title</title>
                  <desc id="desc-75">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small outline button with icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgOutlineAnimated}
            componentName="ButtonRoundedLgOutlineAnimated"
          >
            {/*    <!-- Component: Large outline button with animation  --> */}
            <button className="group inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded border border-emerald-500 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-5 w-5 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-66 desc-66"
                >
                  <title id="title-66">Icon title</title>
                  <desc id="desc-66">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Large outline button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseOutlineAnimated}
            componentName="ButtonRoundedBaseOutlineAnimated"
          >
            {/*    <!-- Component: Base outline button with animation  --> */}
            <button className="group inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-5">
                <svg
                  className="h-5 w-5 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-71 desc-71"
                >
                  <title id="title-71">Icon title</title>
                  <desc id="desc-71">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Base outline button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmOutlineAnimated}
            componentName="ButtonRoundedSmOutlineAnimated"
          >
            {/*    <!-- Component: Small outline button with animation  --> */}
            <button className="group inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded border border-emerald-500 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-4 w-4 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-76 desc-76"
                >
                  <title id="title-76">Icon title</title>
                  <desc id="desc-76">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Small outline button with animation  --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
