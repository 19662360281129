import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonAllSecondaryPreview(props) {
  const {
    ButtonRoundedLgSecondaryBasic,
    ButtonRoundedLgSecondaryElevated,
    ButtonRoundedLgSecondaryDisabled,
    ButtonRoundedLgSecondaryLeadingIcon,
    ButtonRoundedLgSecondaryTrailingIcon,
    ButtonRoundedLgSecondaryBadge,
    ButtonRoundedLgSecondaryIcon,
    ButtonRoundedLgSecondaryAnimated,
  } = props

  const {
    ButtonRoundedBaseSecondaryBasic,
    ButtonRoundedBaseSecondaryElevated,
    ButtonRoundedBaseSecondaryDisabled,
    ButtonRoundedBaseSecondaryLeadingIcon,
    ButtonRoundedBaseSecondaryTrailingIcon,
    ButtonRoundedBaseSecondaryBadge,
    ButtonRoundedBaseSecondaryIcon,
    ButtonRoundedBaseSecondaryAnimated,
  } = props

  const {
    ButtonRoundedSmSecondaryBasic,
    ButtonRoundedSmSecondaryElevated,
    ButtonRoundedSmSecondaryDisabled,
    ButtonRoundedSmSecondaryLeadingIcon,
    ButtonRoundedSmSecondaryTrailingIcon,
    ButtonRoundedSmSecondaryBadge,
    ButtonRoundedSmSecondaryIcon,
    ButtonRoundedSmSecondaryAnimated,
  } = props
  return (
    <>
      <div className="flex w-full flex-col items-start justify-center gap-20">
        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryBasic}
            componentName="ButtonRoundedLgSecondaryBasic"
          >
            {/*    <!-- Component: Large secondary basic button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Large button</span>
            </button>
            {/*    <!-- End Large secondary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryBasic}
            componentName="ButtonRoundedBaseSecondaryBasic"
          >
            {/*    <!-- Component: Base secondary basic button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Base button</span>
            </button>
            {/*    <!-- End Base secondary basic button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryBasic}
            componentName="ButtonRoundedSmSecondaryBasic"
          >
            {/*    <!-- Component: Small secondary basic button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Small button</span>
            </button>
            {/*    <!-- End Small secondary basic button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryElevated}
            componentName="ButtonRoundedLgSecondaryElevated"
          >
            {/*    <!-- Component: Large secondary elevated button --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 shadow-lg shadow-emerald-100 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-200 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Elevated large</span>
            </button>
            {/*    <!-- End Large secondary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryElevated}
            componentName="ButtonRoundedBaseSecondaryElevated"
          >
            {/*    <!-- Component: Base secondary elevated button --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 shadow-md shadow-emerald-100 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-200 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-100 disabled:bg-emerald-100 disabled:shadow-none">
              <span>Elevated base</span>
            </button>
            {/*    <!-- End Base secondary elevated button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryElevated}
            componentName="ButtonRoundedSmSecondaryElevated"
          >
            {/*    <!-- Component: Small secondary elevated button --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 shadow-md shadow-emerald-100 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 hover:shadow-md hover:shadow-emerald-100 focus:bg-emerald-200 focus:text-emerald-700 focus:shadow-md focus:shadow-emerald-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Elevated small</span>
            </button>
            {/*    <!-- End Small secondary elevated button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryDisabled}
            componentName="ButtonRoundedLgSecondaryDisabled"
          >
            {/*    <!-- Component: Large secondary disabled button --> */}
            <button
              disabled
              className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none"
            >
              <span>Disabled large</span>
            </button>
            {/*    <!-- End Large secondary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryDisabled}
            componentName="ButtonRoundedBaseSecondaryDisabled"
          >
            {/*    <!-- Component: Base secondary disabled button --> */}
            <button
              disabled
              className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-100 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none"
            >
              <span>Disabled base</span>
            </button>
            {/*    <!-- End Base secondary disabled button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryDisabled}
            componentName="ButtonRoundedSmSecondaryDisabled"
          >
            {/*    <!-- Component: Small secondary disabled button --> */}
            <button
              disabled
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none"
            >
              <span>Disabled small</span>
            </button>
            {/*    <!-- End Small secondary disabled button --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryLeadingIcon}
            componentName="ButtonRoundedLgSecondaryLeadingIcon"
          >
            {/*    <!-- Component: Large secondary button with leading icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-16 desc-16"
                >
                  <title id="title-16">Icon title</title>
                  <desc id="desc-16">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryLeadingIcon}
            componentName="ButtonRoundedBaseSecondaryLeadingIcon"
          >
            {/*    <!-- Component: Base secondary button with leading icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="order-2">lead icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-21 desc-21"
                >
                  <title id="title-21">Icon title</title>
                  <desc id="desc-21">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with leading icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryLeadingIcon}
            componentName="ButtonRoundedSmSecondaryLeadingIcon"
          >
            {/*    <!-- Component: Small secondary button with leading icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="order-2">Lead icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-26 desc-26"
                >
                  <title id="title-26">Icon title</title>
                  <desc id="desc-26">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with leading icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryTrailingIcon}
            componentName="ButtonRoundedLgSecondaryTrailingIcon"
          >
            {/*    <!-- Component: Large secondary button with trailing icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-17 desc-17"
                >
                  <title id="title-17">Icon title</title>
                  <desc id="desc-17">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryTrailingIcon}
            componentName="ButtonRoundedBaseSecondaryTrailingIcon"
          >
            {/*    <!-- Component: Base secondary button with trailing icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-22 desc-22"
                >
                  <title id="title-22">Icon title</title>
                  <desc id="desc-22">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with trailing icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryTrailingIcon}
            componentName="ButtonRoundedSmSecondaryTrailingIcon"
          >
            {/*    <!-- Component: Small secondary button with trailing icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Trail icon</span>
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-27 desc-27"
                >
                  <title id="title-27">Icon title</title>
                  <desc id="desc-27">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with trailing icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryBadge}
            componentName="ButtonRoundedLgSecondaryBadge"
          >
            {/*    <!-- Component: Large secondary button with badge  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Icon & Badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-18 desc-18"
                >
                  <title id="title-18">Icon title</title>
                  <desc id="desc-18">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Large secondary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryBadge}
            componentName="ButtonRoundedBaseSecondaryBadge"
          >
            {/*    <!-- Component: Base secondary button with badge  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-23 desc-23"
                >
                  <title id="title-23">Icon title</title>
                  <desc id="desc-23">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-2 inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-xs text-white">
                  9<span className="sr-only"> new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Base secondary button with badge  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryBadge}
            componentName="ButtonRoundedSmSecondaryBadge"
          >
            {/*    <!-- Component: Small secondary button with badge  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Icon & badge</span>
              <span className="relative only:-mx-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  role="graphics-symbol"
                  aria-labelledby="title-28 desc-28"
                >
                  <title id="title-28">Icon title</title>
                  <desc id="desc-28">
                    A more detailed description of the icon
                  </desc>
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="absolute -top-0.5 -right-0.5 rounded-full bg-pink-500 p-1">
                  <span className="sr-only">8 new emails</span>
                </span>
              </span>
            </button>
            {/*    <!-- End Small secondary button with badge  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryIcon}
            componentName="ButtonRoundedLgSecondaryIcon"
          >
            {/*    <!-- Component: Large secondary button with icon  --> */}
            <button className="inline-flex h-12 items-center justify-center gap-2 self-center justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="relative only:-mx-6">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-19 desc-19"
                >
                  <title id="title-19">Icon title</title>
                  <desc id="desc-19">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryIcon}
            componentName="ButtonRoundedBaseSecondaryIcon"
          >
            {/*    <!-- Component: Base secondary button with icon  --> */}
            <button className="inline-flex h-10 items-center justify-center gap-2 self-center justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="relative only:-mx-5">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-24 desc-24"
                >
                  <title id="title-24">Icon title</title>
                  <desc id="desc-24">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with icon  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryIcon}
            componentName="ButtonRoundedSmSecondaryIcon"
          >
            {/*    <!-- Component: Small secondary button with icon  --> */}
            <button className="inline-flex h-8 items-center justify-center gap-2 self-center justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span className="relative only:-mx-4">
                <span className="sr-only">Button description</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  role="graphics-symbol"
                  aria-labelledby="title-29 desc-29"
                >
                  <title id="title-29">Icon title</title>
                  <desc id="desc-29">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with icon  --> */}
          </CopyComponent>
        </div>

        <div className="mx-auto flex w-full max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <CopyComponent
            copyToClipboardCode={ButtonRoundedLgSecondaryAnimated}
            componentName="ButtonRoundedLgSecondaryAnimated"
          >
            {/*    <!-- Component: Large secondary button with animation  --> */}
            <button className="group inline-flex h-12 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-5 w-5 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-20 desc-20"
                >
                  <title id="title-20">Icon title</title>
                  <desc id="desc-20">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Large secondary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedBaseSecondaryAnimated}
            componentName="ButtonRoundedBaseSecondaryAnimated"
          >
            {/*    <!-- Component: Base secondary button with animation  --> */}
            <button className="group inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-5 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-5">
                <svg
                  className="h-5 w-5 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-25 desc-25"
                >
                  <title id="title-25">Icon title</title>
                  <desc id="desc-25">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Base secondary button with animation  --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={ButtonRoundedSmSecondaryAnimated}
            componentName="ButtonRoundedSmSecondaryAnimated"
          >
            {/*    <!-- Component: Small secondary button with animation  --> */}
            <button className="group inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-emerald-50 px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none">
              <span>Animated</span>
              <span className="relative only:-mx-6">
                <svg
                  className="h-4 w-4 animate-spin text-emerald-500 group-hover:text-emerald-600 group-focus:text-emerald-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="graphics-symbol"
                  aria-labelledby="title-30 desc-30"
                >
                  <title id="title-30">Icon title</title>
                  <desc id="desc-30">
                    A more detailed description of the icon
                  </desc>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
            {/*    <!-- End Small secondary button with animation  --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
