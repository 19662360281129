import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function ButtonsAllAppPreview(props) {
  const {
    ButtonRoundedLgAppPrimaryApple,
    ButtonRoundedLgAppPrimaryGoogle,
    ButtonRoundedLgAppBasicApple,
    ButtonRoundedLgAppBasicGoogle,
    ButtonRoundedLgAppOutlineApple,
    ButtonRoundedLgAppOutlineGoogle,
  } = props

  const {
    ButtonRoundedBaseAppPrimaryApple,
    ButtonRoundedBaseAppPrimaryGoogle,
    ButtonRoundedBaseAppBasicApple,
    ButtonRoundedBaseAppBasicGoogle,
    ButtonRoundedBaseAppOutlineApple,
    ButtonRoundedBaseAppOutlineGoogle,
  } = props

  return (
    <>
      <div className="flex w-full flex-col items-start justify-center gap-20">
        <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-x-6 gap-y-20 md:flex-row md:items-start">
            <CopyComponent
              copyToClipboardCode={ButtonRoundedLgAppPrimaryApple}
              componentName="ButtonRoundedLgAppPrimaryApple"
            >
              {/*      <!-- Component: Large primary apple app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-16 min-w-44 items-center justify-start gap-2 whitespace-nowrap rounded bg-emerald-500 px-3.5 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-10 w-10"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="App store icon"
                >
                  <path
                    d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="flex flex-col">
                  <span className="text-xs font-normal">Get it on</span>
                  <span>App Store</span>
                </span>
              </a>
              {/*      <!-- End Large primary apple app button  --> */}
            </CopyComponent>

            <CopyComponent
              copyToClipboardCode={ButtonRoundedLgAppPrimaryGoogle}
              componentName="ButtonRoundedLgAppPrimaryGoogle"
            >
              {/*      <!-- Component: Large primary google app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-16 min-w-44 items-center justify-start gap-2 whitespace-nowrap rounded bg-emerald-500 px-3.5 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-10 w-10"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="Google play icon"
                >
                  <path
                    d="M21.9219 19.6222L27.8405 13.7036L8.72367 2.95928C7.45668 2.27475 6.26976 2.17868 5.22895 2.92726L21.9219 19.6222ZM28.8316 26.5339L34.9843 23.0732C36.1853 22.4007 36.8438 21.4479 36.8438 20.3911C36.8438 19.3363 36.203 18.3993 35.0041 17.7267L29.4337 14.5983L23.1452 20.8495L28.8316 26.5339ZM4.20016 4.38239C4.07206 4.7767 4 5.21704 4 5.69941V35.1322C4 35.8948 4.16813 36.5513 4.47237 37.0738L20.6789 20.8652L4.20016 4.38239ZM21.9219 22.1061L5.8054 38.2246C6.11364 38.3427 6.4459 38.4048 6.79617 38.4048C7.42065 38.4048 8.07116 38.2206 8.73367 37.8524L27.258 27.4503L21.9219 22.1061Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="flex flex-col">
                  <span className="text-xs font-normal">Get it on</span>
                  <span>Google Play</span>
                </span>
              </a>
              {/*      <!-- End Large primary google app button  --> */}
            </CopyComponent>
          </div>
          <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-x-6 gap-y-20 md:flex-row md:items-start">
            <CopyComponent
              copyToClipboardCode={ButtonRoundedBaseAppPrimaryApple}
              componentName="ButtonRoundedBaseAppPrimaryApple"
            >
              {/*      <!-- Component: Base sized primary apple app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-12 min-w-40 items-center justify-start gap-2 whitespace-nowrap rounded bg-emerald-500 px-3.5 text-sm font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-8 w-8"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="App store icon"
                >
                  <path
                    d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z"
                    fill="currentColor"
                  />
                </svg>
                <span>App Store</span>
              </a>
              {/*      <!-- End Base sized primary apple app button  --> */}
            </CopyComponent>

            <CopyComponent
              copyToClipboardCode={ButtonRoundedBaseAppPrimaryGoogle}
              componentName="ButtonRoundedBaseAppPrimaryGoogle"
            >
              {/*      <!-- Component: Base sized primary google app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-12 min-w-40 items-center justify-start gap-2 whitespace-nowrap rounded bg-emerald-500 px-3.5 text-sm font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-8 w-8"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="Google play icon"
                >
                  <path
                    d="M21.9219 19.6222L27.8405 13.7036L8.72367 2.95928C7.45668 2.27475 6.26976 2.17868 5.22895 2.92726L21.9219 19.6222ZM28.8316 26.5339L34.9843 23.0732C36.1853 22.4007 36.8438 21.4479 36.8438 20.3911C36.8438 19.3363 36.203 18.3993 35.0041 17.7267L29.4337 14.5983L23.1452 20.8495L28.8316 26.5339ZM4.20016 4.38239C4.07206 4.7767 4 5.21704 4 5.69941V35.1322C4 35.8948 4.16813 36.5513 4.47237 37.0738L20.6789 20.8652L4.20016 4.38239ZM21.9219 22.1061L5.8054 38.2246C6.11364 38.3427 6.4459 38.4048 6.79617 38.4048C7.42065 38.4048 8.07116 38.2206 8.73367 37.8524L27.258 27.4503L21.9219 22.1061Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span>Google Play</span>
              </a>
              {/*      <!-- End Base sized primary google app button  --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-x-6 gap-y-20 md:flex-row md:items-start">
            <CopyComponent
              copyToClipboardCode={ButtonRoundedLgAppBasicApple}
              componentName="ButtonRoundedLgAppBasicApple"
            >
              {/*      <!-- Component: Large basic apple app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-16 min-w-44 items-center justify-start gap-2 whitespace-nowrap rounded bg-slate-900 px-3.5 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-10 w-10"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="App store icon"
                >
                  <path
                    d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="flex flex-col">
                  <span className="text-xs font-normal">Get it on</span>
                  <span>App Store</span>
                </span>
              </a>
              {/*      <!-- End Large basic apple app button  --> */}
            </CopyComponent>

            <CopyComponent
              copyToClipboardCode={ButtonRoundedLgAppBasicGoogle}
              componentName="ButtonRoundedLgAppBasicGoogle"
            >
              {/*      <!-- Component: Large basic google app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-16 min-w-44 items-center justify-start gap-2 whitespace-nowrap rounded bg-slate-900 px-3.5 text-base font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-10 w-10"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="Google play icon"
                >
                  <path
                    d="M21.9219 19.6222L27.8405 13.7036L8.72367 2.95928C7.45668 2.27475 6.26976 2.17868 5.22895 2.92726L21.9219 19.6222ZM28.8316 26.5339L34.9843 23.0732C36.1853 22.4007 36.8438 21.4479 36.8438 20.3911C36.8438 19.3363 36.203 18.3993 35.0041 17.7267L29.4337 14.5983L23.1452 20.8495L28.8316 26.5339ZM4.20016 4.38239C4.07206 4.7767 4 5.21704 4 5.69941V35.1322C4 35.8948 4.16813 36.5513 4.47237 37.0738L20.6789 20.8652L4.20016 4.38239ZM21.9219 22.1061L5.8054 38.2246C6.11364 38.3427 6.4459 38.4048 6.79617 38.4048C7.42065 38.4048 8.07116 38.2206 8.73367 37.8524L27.258 27.4503L21.9219 22.1061Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="flex flex-col">
                  <span className="text-xs font-normal">Get it on</span>
                  <span>Google Play</span>
                </span>
              </a>
              {/*      <!-- End Large basic google app button  --> */}
            </CopyComponent>
          </div>
          <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-x-6 gap-y-20 md:flex-row md:items-start">
            <CopyComponent
              copyToClipboardCode={ButtonRoundedBaseAppBasicApple}
              componentName="ButtonRoundedBaseAppBasicApple"
            >
              {/*      <!-- Component: Base sized basic apple app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-12 min-w-40 items-center justify-start gap-2 whitespace-nowrap rounded bg-slate-900 px-3.5 text-sm font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-8 w-8"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="App store icon"
                >
                  <path
                    d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z"
                    fill="currentColor"
                  />
                </svg>
                <span>App Store</span>
              </a>
              {/*      <!-- End Base sized basic apple app button  --> */}
            </CopyComponent>

            <CopyComponent
              copyToClipboardCode={ButtonRoundedBaseAppBasicGoogle}
              componentName="ButtonRoundedBaseAppBasicGoogle"
            >
              {/*      <!-- Component: Base sized basic google app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-12 min-w-40 items-center justify-start gap-2 whitespace-nowrap rounded bg-slate-900 px-3.5 text-sm font-semibold tracking-wide text-slate-50 transition duration-300 hover:bg-slate-950 focus:bg-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-8 w-8"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="Google play icon"
                >
                  <path
                    d="M21.9219 19.6222L27.8405 13.7036L8.72367 2.95928C7.45668 2.27475 6.26976 2.17868 5.22895 2.92726L21.9219 19.6222ZM28.8316 26.5339L34.9843 23.0732C36.1853 22.4007 36.8438 21.4479 36.8438 20.3911C36.8438 19.3363 36.203 18.3993 35.0041 17.7267L29.4337 14.5983L23.1452 20.8495L28.8316 26.5339ZM4.20016 4.38239C4.07206 4.7767 4 5.21704 4 5.69941V35.1322C4 35.8948 4.16813 36.5513 4.47237 37.0738L20.6789 20.8652L4.20016 4.38239ZM21.9219 22.1061L5.8054 38.2246C6.11364 38.3427 6.4459 38.4048 6.79617 38.4048C7.42065 38.4048 8.07116 38.2206 8.73367 37.8524L27.258 27.4503L21.9219 22.1061Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span>Google Play</span>
              </a>
              {/*      <!-- End Base sized basic google app button  --> */}
            </CopyComponent>
          </div>
        </div>
        <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-20 md:flex-row md:items-start">
          <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-x-6 gap-y-20 md:flex-row md:items-start">
            <CopyComponent
              copyToClipboardCode={ButtonRoundedLgAppOutlineApple}
              componentName="ButtonRoundedLgAppOutlineApple"
            >
              {/*      <!-- Component: Large outline apple app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-16 min-w-44 items-center justify-start gap-2 whitespace-nowrap rounded border border-slate-900 px-3.5 text-base font-semibold tracking-wide text-slate-900 transition duration-300 hover:border-slate-950 focus:border-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-10 w-10"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="App store icon"
                >
                  <path
                    d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="flex flex-col">
                  <span className="text-xs font-normal">Get it on</span>
                  <span>App Store</span>
                </span>
              </a>
              {/*      <!-- End Large outline apple app button  --> */}
            </CopyComponent>

            <CopyComponent
              copyToClipboardCode={ButtonRoundedLgAppOutlineGoogle}
              componentName="ButtonRoundedLgAppOutlineGoogle"
            >
              {/*      <!-- Component: Large outline google app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-16 min-w-44 items-center justify-start gap-2 whitespace-nowrap rounded border border-slate-900 px-3.5 text-base font-semibold tracking-wide text-slate-900 transition duration-300 hover:border-slate-950 focus:border-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-10 w-10"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="Google play icon"
                >
                  <path
                    d="M21.9219 19.6222L27.8405 13.7036L8.72367 2.95928C7.45668 2.27475 6.26976 2.17868 5.22895 2.92726L21.9219 19.6222ZM28.8316 26.5339L34.9843 23.0732C36.1853 22.4007 36.8438 21.4479 36.8438 20.3911C36.8438 19.3363 36.203 18.3993 35.0041 17.7267L29.4337 14.5983L23.1452 20.8495L28.8316 26.5339ZM4.20016 4.38239C4.07206 4.7767 4 5.21704 4 5.69941V35.1322C4 35.8948 4.16813 36.5513 4.47237 37.0738L20.6789 20.8652L4.20016 4.38239ZM21.9219 22.1061L5.8054 38.2246C6.11364 38.3427 6.4459 38.4048 6.79617 38.4048C7.42065 38.4048 8.07116 38.2206 8.73367 37.8524L27.258 27.4503L21.9219 22.1061Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="flex flex-col">
                  <span className="text-xs font-normal">Get it on</span>
                  <span>Google Play</span>
                </span>
              </a>
              {/*      <!-- End Large outline google app button  --> */}
            </CopyComponent>
          </div>
          <div className="mx-auto flex max-w-[40rem] flex-col items-center justify-around gap-x-6 gap-y-20 md:flex-row md:items-start">
            <CopyComponent
              copyToClipboardCode={ButtonRoundedBaseAppOutlineApple}
              componentName="ButtonRoundedBaseAppOutlineApple"
            >
              {/*      <!-- Component: Base sized outline apple app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-12 min-w-40 items-center justify-start gap-2 whitespace-nowrap rounded border border-slate-900 px-3.5 text-sm font-semibold tracking-wide text-slate-900 transition duration-300 hover:border-slate-950 focus:border-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-8 w-8"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="App store icon"
                >
                  <path
                    d="M30.293 21.0245C30.2769 18.0748 31.6111 15.8485 34.3116 14.209C32.8006 12.047 30.518 10.8575 27.5041 10.6244C24.6509 10.3993 21.5325 12.2881 20.3912 12.2881C19.1856 12.2881 16.4208 10.7047 14.2508 10.7047C9.76604 10.7771 5 14.2813 5 21.4103C5 23.516 5.38578 25.6914 6.15735 27.9365C7.18611 30.8861 10.8993 38.1196 14.7732 37.999C16.7986 37.9508 18.2292 36.5603 20.8654 36.5603C23.4212 36.5603 24.7473 37.999 27.0058 37.999C30.9119 37.9427 34.2714 31.3683 35.2519 28.4106C30.0117 25.9432 30.293 21.1772 30.293 21.0245ZM25.7439 7.82744C27.9381 5.2234 27.7372 2.85243 27.6729 2.00049C25.7359 2.11301 23.4935 3.31859 22.2156 4.80546C20.8091 6.39682 19.9813 8.36593 20.1581 10.5842C22.2558 10.7449 24.1687 9.66795 25.7439 7.82744Z"
                    fill="currentColor"
                  />
                </svg>
                <span>App Store</span>
              </a>
              {/*      <!-- End Base sized outline apple app button  --> */}
            </CopyComponent>

            <CopyComponent
              copyToClipboardCode={ButtonRoundedBaseAppOutlineGoogle}
              componentName="ButtonRoundedBaseAppOutlineGoogle"
            >
              {/*      <!-- Component: Base sized outline google app button  --> */}
              <a
                href="javascript:void(0)"
                className="inline-flex h-12 min-w-40 items-center justify-start gap-2 whitespace-nowrap rounded border border-slate-900 px-3.5 text-sm font-semibold tracking-wide text-slate-900 transition duration-300 hover:border-slate-950 focus:border-slate-950 focus-visible:outline-none"
              >
                <svg
                  width="40"
                  height="40"
                  className="h-8 w-8"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="graphics-symbol"
                  aria-label="Google play icon"
                >
                  <path
                    d="M21.9219 19.6222L27.8405 13.7036L8.72367 2.95928C7.45668 2.27475 6.26976 2.17868 5.22895 2.92726L21.9219 19.6222ZM28.8316 26.5339L34.9843 23.0732C36.1853 22.4007 36.8438 21.4479 36.8438 20.3911C36.8438 19.3363 36.203 18.3993 35.0041 17.7267L29.4337 14.5983L23.1452 20.8495L28.8316 26.5339ZM4.20016 4.38239C4.07206 4.7767 4 5.21704 4 5.69941V35.1322C4 35.8948 4.16813 36.5513 4.47237 37.0738L20.6789 20.8652L4.20016 4.38239ZM21.9219 22.1061L5.8054 38.2246C6.11364 38.3427 6.4459 38.4048 6.79617 38.4048C7.42065 38.4048 8.07116 38.2206 8.73367 37.8524L27.258 27.4503L21.9219 22.1061Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span>Google Play</span>
              </a>
              {/*      <!-- End Base sized outline google app button  --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
